<template>
	<div class="box">
		<div class="top">
			<ul class='left'>
				<li>
					<span>Booster</span>
					<div class="num">{{ total }}</div>
				</li>
			</ul>

			<div class="right">
				<div class="search_box">
					<input v-model="search" placeholder="Search by keywords" @keyup.enter="goSearch">
					<div class="icon icon_search" @click="goSearch"></div>
				</div>
				<!-- <button @click="openPop(1)">Add new</button> -->
			</div>
		</div>
		<div class="con-table">
			<table>
				<thead>
					<tr>
						<th style="width: 5%;">Info</th>
						<th style="width: 15%;">Nick name</th>
						<th style="width: 12%;">Email</th>
						<th style="width: 12%;">Location</th>
						<th style="width: 12%;">Discord</th>
						<th style="width: 12%;">Discord Link</th>
						<th style="width: 10%;" class="icon_box">
							Date
							<div class="icon icon_down" :style="{ 'transform': sort_data == 2 ? 'rotateX(180deg)' : '' }"
								@click="sort_data = sort_data == 1 ? 2 : 1"></div>
						</th>
						<th style="width: 12%;">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in list" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
						<td class="icon_box">
							<div class="icon icon_info"></div>
						</td>
						<td :style="{ color: item.ban ? 'red' : '' }">{{ item.username }}</td>
						<td>{{ item.email }}</td>
						<td>{{ item.country_name || 'not upload' }}</td>
						<td>{{ item.discord }}</td>
						<td class="text_purple" @click="openLink(item.discord_link)">
							<span style="cursor: pointer">{{ item.discord_link }}</span>
						</td>
						<td>{{ getDate(item.created_at) }}</td>
						<td class="icon_box">
							<div class="icon icon_ban_red" v-if="item.ban" @click="openPop_jieban(item)"></div>
							<div class="icon icon_ban" v-else @click="openPop_ban(item)"></div>
							<div class="icon icon_del" @click="openPop_del(item)"></div>
							<div class="icon icon_edit" @click="openPop(2, item)"></div>
							<!-- <div class="icon icon_copy"></div> -->
							<div class="icon icon_chat" style="opacity: 0.6;margin-left:0" @click="goChat(item)"></div>
							<div class="icon icon_see" @click="openPop(3, item)"></div>
						</td>

					</tr>
				</tbody>
			</table>

		</div>
		<div class="pop-bg" v-if="bg" @click="close()">

			<div class="pop_box" v-if="pop_del" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						Delete Booster
					</div>
					<div class="text-gray left">
						Are you sure you want to delete the Booster?
					</div>
					<div class="inp-box">
						<textarea v-model="comment"></textarea>
					</div>
					<div class="button-box">
						<button class="cancel" @click="close">No</button>
						<button class="submit" @click="del">Yes</button>
					</div>
				</div>

			</div>
			<div class="pop_box" v-if="pop_ban" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						Banned user?
					</div>
					<div class="title-second">
						{{ username }} ID {{ id }}
					</div>
					<div class="inp-box">
						<div class="text-gray left">Banned time ( hour )</div>
						<input v-model="hour" type="number" placeholder="Time" style="margin-top:5px;" />
					</div>
					<ul class="time-box">
						<li @click="setHour(0.25)">15 min.</li>
						<li @click="setHour(0.5)">30 min.</li>
						<li @click="setHour(1)">1 h.</li>
						<li @click="setHour(3)">3 h.</li>
						<li @click="setHour(5)">5 h.</li>
						<li @click="setHour(12)">12 h.</li>
					</ul>
					<div class="button-box">
						<button class="cancel" @click="close">Cancel</button>
						<button class="submit" @click="save_ban">Banned</button>
					</div>
				</div>
			</div>
			<div class="pop_box" v-if="pop_confirmban" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title" style="font-size:26px;margin-bottom: 40px;">
						Are you sure you want to ban this booster ?
					</div>
					<div class="text-gray left">
					</div>
					<div class="button-box">
						<button class="cancel" @click="close">No</button>
						<button class="submit" @click="save_comfirmrban">Ban</button>
					</div>
				</div>
			</div>
			<div class="pop_box" v-if="pop_jieban" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title" style="font-size:26px;margin-bottom: 40px;">
						Unban a user ?
					</div>
					<div class="text-gray left">
					</div>
					<div class="button-box">
						<button class="cancel" @click="close">No</button>
						<button class="submit" @click="jieBan">Unban</button>
					</div>
				</div>
			</div>
			<div class="pop_box" v-if="pop_see" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						Booster
					</div>
					<!-- <div class="inp-box">
						<div class="tab-box">
							<div class="item" :class="popTab == 1 ? 'active' : ''" @click="popTab = 1">Personal info</div>
							<div class="item" :class="popTab == 2 ? 'active' : ''" @click="popTab = 1">Access</div>
						</div>
					</div> -->
					<div class="flex">
						<div class="inp-box">
							<div class="text-gray left">Nick Name</div>
							<div class="input">{{ username }}</div>
						</div>
						<div class="inp-box">
							<div class="text-gray left">Email</div>
							<div class="input">{{ email }}</div>
						</div>
					</div>
					<div class="flex">
						<div class="inp-box">
							<div class="text-gray left">Discord Username</div>
							<div class="input">{{ discordUsername }}</div>
						</div>
						<div class="inp-box">
							<div class="text-gray left">Location</div>
							<div class="input">{{ location }}</div>
						</div>
					</div>
					<div class="inp-box">
						<div class="text-gray left">Link to Account</div>
						<div class="input">{{ link }}</div>
					</div>
					<div class="inp-box">
						<div class="text-gray left">About</div>
						<div class="input">{{ about }}</div>
					</div>
					<div class="inp-box">
						<div class="text-gray left">Win rate</div>
						<div class="input">{{ win_rate }}</div>
					</div>
					<div class="inp-box">
						<div class="text-gray left">Kda</div>
						<div class="input">{{ kda }}</div>
					</div>
					<div class="inp-box">
						<div class="text-gray left">Role</div>
						<div class="input">{{ role }}</div>
					</div>
				</div>

			</div>
			<div class="pop_box" v-if="pop_create" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						{{ popType == 1 ? 'New Booster' : 'Edit Booster' }}
					</div>
					<!-- <div class="inp-box">
						<div class="tab-box">
							<div class="item" :class="popTab == 1 ? 'active' : ''" @click="popTab = 1">Personal info</div>
							<div class="item" :class="popTab == 2 ? 'active' : ''" @click="popTab = 1">Access</div>
						</div>
					</div> -->
					<div class="flex">
						<div class="inp-box">
							<div class="text-gray left" v-if="username">Nick Name</div>
							<input v-model="username" placeholder="Nick Name"
								:style="{ 'margin-top': username ? '5px' : '20px' }" />
						</div>
						<div class="inp-box">
							<div class="text-gray left" v-if="email">Email</div>
							<input v-model="email" placeholder="Email" :style="{ 'margin-top': email ? '5px' : '20px' }" />
						</div>
					</div>
					<div class="flex">
						<div class="inp-box">
							<div class="text-gray left" v-if="discordUsername">Discord Username</div>
							<input v-model="discordUsername" placeholder="Discord Username"
								:style="{ 'margin-top': discordUsername ? '5px' : '20px' }" />
						</div>
						<div class="inp-box">
							<div class="text-gray left" v-if="location">Location</div>
							<el-select v-model="location" placeholder="Location" size="large" class="myselect"
								@change="onChangeLocation" :style="{ 'margin-top': location ? '5px' : '20px' }">
								<el-option v-for="item in locationList" :key="item.country_code" :label="item.country_name"
									:value="item.country_code" />
							</el-select>
						</div>
					</div>
					<div class="inp-box">
						<div class="text-gray left" v-if="link">Link to Account</div>
						<input v-model="link" placeholder="Link to Account"
							:style="{ 'margin-top': link ? '5px' : '20px' }" />
					</div>
					<div class="inp-box">
						<div class="text-gray left" v-if="about">About</div>
						<input v-model="about" placeholder="About" :style="{ 'margin-top': about ? '5px' : '20px' }" />
					</div>
					<div class="inp-box">
						<div class="text-gray left" v-if="win_rate">Win rate</div>
						<input v-model="win_rate" placeholder="Win rate" type="number"
							:style="{ 'margin-top': win_rate ? '5px' : '20px' }" />
					</div>
					<div class="inp-box">
						<div class="text-gray left" v-if="kda">Kda</div>
						<input v-model="kda" placeholder="Kda" :style="{ 'margin-top': kda ? '5px' : '20px' }" type="number"
							step="0.01" />
					</div>
					<div class="inp-box">
						<div class="text-gray left" v-if="role">Role</div>
						<el-select v-model="role" placeholder="Role" size="large" class="myselect"
							:style="{ 'margin-top': role ? '5px' : '20px' }">
							<el-option label="Booster" :value="1" />
							<!-- <el-option label="Coach" :value="2" /> -->
						</el-select>
					</div>



					<div class="button-box">
						<button class="cancel" @click="close">Cancel</button>
						<button class="submit" @click="save">Save</button>
					</div>
				</div>

			</div>
		</div>
		<pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
			@backPage='backPage'></pagination>
	</div>
</template>

<script>
import {
	ElMessage
} from 'element-plus'
import Api from '../../../utils/api.js';
import pagination from '../../common/pagination.vue';
export default {
	components: {
		pagination
	},
	data() {
		return {
			kda: '',
			win_rate: '',
			search: '',
			pageSize: 8,
			nowPage: 1,
			total: 0,
			list: [],
			bg: false,
			pop_del: false,
			pop_create: false,
			pop_ban: false,
			pop_jieban: false,
			pop_confirmban: false,
			pop_see: false,
			popType: 1,
			comment: '',
			sort_data: 1,
			username: '',
			email: '',
			id: "",
			locationList: [],
			location: '',
			discordUsername: '',
			link: '',
			about: '',
			role: '',
			popTab: 1,
			bannedTime: '',
			hour: ''


		}

	},
	watch: {
		sort_data(e) {
			this.getList();
		},
		win_rate(e) {
			if (e) {
				if (e > 100 || e < 1) {
					ElMessage('Please enter a number between 1-100');
					return;
				}
			}
		},
		kda(e) {

		},
	},
	created() {

	},
	mounted() {
		let table = document.getElementsByClassName('con-table')[0];
		this.pageSize = parseInt((table.clientHeight - 50) / 50);
		this.getList();
		this.getCountry();
	},
	methods: {
		//add
		openLink(item){
			window.open(item,'_blank')
		},
		save_comfirmrban() {
			Api.boosterBan({
				booster_id: this.id,
				status: 1,
				hour: this.hour
			}).then(res => {
				if (res.code == 200) {
					this.close();
					this.getList();
				} else {
					ElMessage.error(res.message);
				}
			})
		},
		jieBan() {
			Api.boosterBan({
				booster_id: this.id,
				status: 2,
			}).then(res => {
				if (res.code == 200) {
					this.close();
					this.getList();
				} else {
					ElMessage.error(res.message);
				}
			})
		},
		save_ban() {
			if (this.hour == '') {
				ElMessage('Please select a time');
				return;
			}
			this.pop_ban = false;
			this.pop_confirmban = true;
			this.bg = true;

		},
		setHour(e) {
			this.hour = e;
			this.bannedTime = e >= 1 ? e + " h." : e * 60 + " min."
		},

		goChat(item) {
			Api.chatHasGroup({
				user_id: item.id,
				user_type: 2
			}).then(res => {
				if (res.code == 200) {
					item.group_id = res.data.group_id;
					this.$router.push({
						name: 'support_chat',
						query: {
							other: JSON.stringify(item),
							folder: 'user'
						}
					})
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		getCountry() {
			Api.indexCountry().then(res => {
				if (res.code == 200) {
					this.locationList = res.data;
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		onChangeLocation(e) {
			console.log(e)
		},
		openPop_ban(item) {
			this.pop_ban = true;
			this.bg = true;
			this.id = item.id;
			this.username = item.username;
		},
		openPop_jieban(item) {
			this.pop_jieban = true;
			this.bg = true;
			this.id = item.id;
			this.username = item.username;
		},
		openPop(e, item) {
			if (e != 3) {
				this.popType = e
				this.pop_create = true;
			} else {
				this.pop_see = true;
			}
			this.bg = true;
			if (item) {
				this.id = item.id;
				Api.personalInfo({
					booster_id: this.id
				}).then(res => {
					if (res.code == 200) {
						this.username = res.data.username;
						this.email = res.data.email;
						this.location = res.data.country_code;
						this.discordUsername = res.data.discord;
						this.link = res.data.discord_link;
						this.win_rate = res.data.win_rate;
						this.kda = res.data.kda;
						this.role = res.data.role;
						this.about = res.data.about;
					} else {
						ElMessage.error(res.message)
					}
				})

			}
		},
		openPop_del(item) {
			// return;
			this.bg = true;
			this.pop_del = true;
			this.id = item.id;
		},
		close() {
			this.pop_del = false;
			this.pop_create = false;
			this.pop_see = false;
			this.pop_ban = false;
			this.pop_jieban = false;
			this.pop_confirmban = false;
			this.bannedTime = '';
			this.hour = '';
			this.bg = false;
			this.username = '';
			this.email = '';
			this.location = '';
			this.discordUsername = '';
			this.link = '';
			this.win_rate = '';
			this.kda = '';
			this.about = '';
			this.role = '';
			this.id = '';
			this.comment = '';
		},
		save() {
			if (this.username == '' || this.email == '' || this.location == '' || this.discordUsername == '' || this.link == "" || this.role == "" || this.about == "") {
				ElMessage('Please complete the information.');
				return;
			}
			let match2 = /^((http|https):\/\/)?(([A-Za-z0-9]+-[A-Za-z0-9]+|[A-Za-z0-9]+)\.)+([A-Za-z]+)[/\?\:]?.*$/;
			if (!match2.test(this.link)) {
				ElMessage({
					message: "Please enter correctly link",
					// type: 'error',
					duration: 5 * 1000
				})
				return
			}
			if (this.popType == 1) {

			} else {
				Api.boosterEdit({
					booster_id: this.id,
					username: this.username,
					email: this.email,
					discord: this.discordUsername,
					country: this.location,
					discord_link: this.link,
					win_rate: this.win_rate,
					kda: this.kda,
					about: this.about,
					role: this.role,
				}).then(res => {
					if (res.code == 200) {
						this.getList();
						this.close();
					} else {
						ElMessage.error(res.message)
					}
				})
			}

		},
		del() {
			if (this.comment == '') {
				ElMessage('Please complete the comment.');
				return;
			}
			Api.boosterDelete({
				user_id: this.id,
				deleted_comment: this.comment
			}).then(res => {
				if (res.code == 200) {
					this.getList();
					this.close();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		getDate(e) {
			const date = new Date(e ? e * 1000 : '');
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = date.getHours();
			let minute = date.getMinutes();
			month = month > 9 ? month : '0' + month;
			day = day > 9 ? day : '0' + day;
			hour = hour > 9 ? hour : '0' + hour;
			minute = minute > 9 ? minute : '0' + minute;
			return `${day}/${month}/${year}`
		},
		goSearch() {
			this.nowPage = 1;
			this.getList();
		},
		change_booster(e, item, index) {
			console.log(e, item);
			item.booster_name_choose = e;
			this.choose_booster_index = index;
		},
		getList() {
			Api.boosterList({
				limit: this.pageSize,
				search: this.search,
				sort: this.sort_data,
				page: this.nowPage
			}).then(res => {
				if (res.code == 200) {
					this.list = res.data.data;
					this.total = res.data.meta.pagination.total;
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		goPage(e) {
			this.nowPage = e
			this.getList();
			console.log('nowPage:', this.nowPage)
		},
		nextPage(e) {
			this.nowPage = e
			this.getList();
			console.log('nowPage:', this.nowPage)
		},
		backPage(e) {
			this.nowPage = e
			this.getList();
			console.log('nowPage:', this.nowPage)
		},
	}
}
</script>
<style>
.pop .inp-box .el-select .el-input__inner {
	margin: 0;
}

.pop .flex .inp-box .el-select .el-input__inner {
	width: 100%;
}
</style>

<style scoped>
.box {
	width: 100%;
	height: 100%;
	position: relative;
}

.con-table {
	height: calc(100% - 106px);
}

.top .left li {
	width: auto;
}

.con-table table tr .icon_box.submit .icon {
	cursor: no-drop !important;
}

.pop .time-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
}

.pop .time-box li {
	color: var(--text-color);
	border-radius: 20px;
	padding: 8px 15px;
	background-color: var(--background-light);
	cursor: pointer;
}

.pop .title-second {
	color: var(--text-color);
	margin-bottom: 20px;
	margin-top: 30px;
	font-size: 16px;
	font-weight: 500;
	text-align: left;
}

.pop .flex {
	display: flex;
	justify-content: space-between;
	width: 380px;

}

.pop .flex .inp-box {
	width: calc(50% - 10px);
}

.pop .flex .inp-box .text-gray {
	width: 100%;
}

.pop .flex .inp-box input {
	padding: 10px 15px;
	width: calc(100% - 30px);
}

.pop .flex .inp-box .myselect {
	width: 100%;
	margin: 0;
}

.pop .flex .inp-box textarea {
	margin: 0 !important;
}


.pop .inp-box {
	margin-bottom: 10px;
}

.pop .inp-box input {
	padding: 10px 15px;
	margin: 0;
}

.pop .tab-box {
	width: 100%;
	border-radius: 20px;
	background-color: var(--background-light3);
	display: flex;
	align-items: center;
	font-size: 16px;
	margin-top: 10px;
}

.pop .tab-box .item {
	width: 50%;
	text-align: center;
	line-height: 35px;
	cursor: pointer;
	color: var(--text-color-light);
}

.pop .tab-box .item.active {
	background-color: var(--submit);
	border-radius: 20px;
	color: var(--text-color);
}

.pop .flex .inp-box .input {
	width: auto;
}
</style>