<template>
	<div class="box">
		<div class="top">
			<ul class='left'>
			</ul>
			<div class="right">
				<button @click="openPop(1)">+ Add new</button>
			</div>
		</div>
		<el-scrollbar ref="scroll" id="list-scroll" class="scroll" :height="scrollbar" @scroll="scroll">
			<div class="con">
				<div class="item" v-for="(item, index) in list" :key="index">

					<!-- <img v-if="item.image" :src="item.image"> -->
					<div class="item_top">
						<div class="left">
							<span>{{ item.title }}</span>
							<div class="label" v-if="item.tag == 1">
								Attention
							</div>
							<div class="label purple" v-if="item.tag == 2">
								Important
							</div>
							<div class="label blue" v-if="item.tag == 3">
								New
							</div>
						</div>
						<div class="right">
							<div class="icon icon_edit" @click="openPop(2, item)"></div>
							<div class="icon icon_del" @click="openPop_del(item)"></div>
						</div>
					</div>
					<p>
						{{ item.isMore ? item.description : (item.description.length > 100 ? item.description.substr(0, 250)
							:
							item.description)
						}}
						<span v-if="item.description.length > 250 && !item.isMore"
							@click.stop="listIsMore(item, 'show')">More...</span>
						<span v-if="item.description.length > 250 && item.isMore"
							@click.stop="listIsMore(item, 'hide')">Close</span>
					</p>
					<div class="item_bottom">
						<div class="user_box">
							<img :src="item.user_avatar" />
							<div>
								<div class="name">{{ item.user_name }}</div>
								<div class="identity">Admin</div>
							</div>
						</div>
						<div class="time" v-html="getDate(item.created_at)">
						</div>
					</div>
				</div>

			</div>
		</el-scrollbar>
		<div class="pop-bg" v-if="bg">
			<div class="pop_box" v-if="pop_del" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						Delete news
					</div>
					<div class="text-gray left">
						Do you want to delete this news?
					</div>
					<div class="inp-box" style="margin-top: 20px;">
						<textarea v-model="comment" placeholder="Comment"></textarea>
					</div>
					<div class="button-box">
						<button class="cancel" @click="close">No</button>
						<button class="submit" @click="del">Yes</button>
					</div>
				</div>

			</div>
			<div class="pop_box" v-if="pop" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						{{ popType == 1 ? 'New news' : 'Edit news' }}
					</div>
					<!-- <div class="inp-box">
						<div class="text-gray left">Language</div>
						<div class="tab-box">
							<div class="item" :class="language == 'EN' ? 'active' : ''" @click="language = 'EN'">EN</div>
							<div class="item" :class="language == 'RU' ? 'active' : ''" @click="language = 'RU'">RU</div>
						</div>
					</div>  -->
					<div class="inp-box" v-if="language == 'EN'">
						<div class="text-gray left">Title</div>
						<input v-model="title" placeholder="Title" />
					</div>
					<!-- <div class="inp-box" v-if="language == 'RU'">
						<div class="text-gray left">Title</div>
						<input v-model="title_ru" placeholder="Title" />
					</div> -->
					<div class="inp-box">
						<div class="text-gray left">Tag</div>
						<div class="tag_box">
							<div class="item submit" :class="tag == 1 ? 'active' : ''" @click="tag = 1">Attention</div>
							<div class="item purple" :class="tag == 2 ? 'active' : ''" @click="tag = 2">Important</div>
							<div class="item blue" :class="tag == 3 ? 'active' : ''" @click="tag = 3">New</div>
						</div>
					</div>

					<div class="inp-box" v-if="language == 'EN'">
						<textarea v-model="content" placeholder="Description"></textarea>
					</div>
					<!-- <div class="inp-box" v-if="language == 'RU'">
						<textarea v-model="content_ru" placeholder="Description"></textarea>
					</div> -->
					<!-- <div class="banner-box" v-if="imageIschange" v-loading="image == ''">
						<img :src="image.full_path" alt="" />
						<div class="icon" @click="delPhoto">
							<div class="icon-del">
							</div>
						</div>
					</div> -->
					<!-- <el-upload v-else class="avatar-uploader" :headers="uploadHeader" :action="uploadUrl" method="post"
						:data="uploadData" list-type="picture" name='file' :show-file-list="false"
						:on-success="photoSuccess" :on-change="photoChange" :before-upload="(file) => beforeUpload(file)">
						<div class="addPhoto">
							+ Add photo
						</div>
					</el-upload> -->
					<div class="button-box">
						<button class="cancel" @click="close">Cancel</button>
						<button v-if="popType == 1" class="submit" @click="save">Create</button>
						<button v-else class="submit" @click="save">Save</button>
					</div>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
import { ElMessage, ElLoading } from 'element-plus'
import Api from '../../utils/api.js';
import pagination from '../common/pagination.vue';
import {
	baseUrl
} from '../../utils/config.js'
export default {
	components: {
		pagination
	},
	data() {
		return {
			uploadUrl: baseUrl + 'upload/images',
			uploadHeader: {},
			uploadData: {
				"dir": 'blog_upload'
			},
			list: [],
			bg: false,
			pop: false,
			pop_del: false,
			popType: 1,
			id: '',
			title: '',
			title_ru: '',
			content: '',
			content_ru: '',
			tag: 1,
			image: '',
			imageIschange: false,
			language: 'EN',
			comment: '',
			page: 1,
			total: 1,
			loading: false,
			scrollbar: '',
		}
	},
	mounted() {
		this.uploadHeader.Authorization = localStorage.getItem("token_type") + ' ' + localStorage.getItem("token");
		let obj = document.getElementsByClassName('box')[0];
		this.scrollbar = (obj.clientHeight - 50) + 'px';
		console.log(this.scrollbar)
		const wrap = this.$refs['scroll'].wrapRef
		wrap.removeEventListener('scroll', () => { this.scrollBarEv(wrap) })
		wrap.addEventListener('scroll', () => { this.scrollBarEv(wrap) })
		this.getList();
	},
	created() {

	},
	methods: {
		listIsMore(item, e) {
			let that = this;
			that.list.forEach((value, index) => {
				value['isMore'] = false
			})
			if (e == 'show') {
				item.isMore = true;
			}
			this.$forceUpdate();
		},
		scroll(e) {
			return
		},
		scrollBarEv(wrap) {
			let poor = wrap.scrollHeight - wrap.clientHeight;
			if (
				poor == parseInt(wrap.scrollTop) ||
				poor == Math.ceil(wrap.scrollTop) ||
				poor == Math.floor(wrap.scrollTop)
			) {
				if (this.list.length < this.total) {
					this.loading = true;
					this.page++;
					this.getList();
				}
			}

		},
		del() {
			let loadingInstance = ElLoading.service({
				background: 'rgba(0, 0, 0, 0.2)',
				lock: true,
				text: 'Setting'
			});
			Api.announcementDelete({
				id: this.id,
				comment: this.comment
			}).then(res => {
				if (res.code == 200) {
					this.close();
					this.$nextTick(() => {
						loadingInstance.close();
					});
					this.getList();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		openPop_del(item) {
			this.bg = true;
			this.pop_del = true;
			this.id = item.id;
		},
		save() {
			if ([this.title,  this.content].every(function (
				item) {
				return item != '';
			})) {
				if (this.popType == 1) {
					let loadingInstance = ElLoading.service({
						background: 'rgba(0, 0, 0, 0.2)',
						lock: true,
						text: 'Setting'
					});
					Api.announcementCreate({
						title_en: this.title,
						title_ru: this.title,
						tag: this.tag,
						description_en: this.content,
						description_ru: this.content,
						// image: this.image.half_path,
					}).then(res => {
						if (res.code == 200) {
							this.close();
							this.$nextTick(() => {
								loadingInstance.close();
							});
							this.getList();

						} else {
							ElMessage.error(res.message)
						}
					})
				} else {
					let loadingInstance = ElLoading.service({
						background: 'rgba(0, 0, 0, 0.2)',
						lock: true,
						text: 'Setting'
					});
					Api.announcementEdit({
						id: this.id,
						title_en: this.title,
						title_ru: this.title,
						tag: this.tag,
						description_en: this.content,
						description_ru: this.content,
						// image: this.image.half_path,
					}).then(res => {
						if (res.code == 200) {
							this.close();
							this.$nextTick(() => {
								loadingInstance.close();
							});
							this.getList();
						} else {
							ElMessage.error(res.message)
						}
					})
				}
			} else {
				ElMessage('Please compelet the information.')
			}

		},
		beforeUpload(file, e) {
			console.log(file)
			if (file.type !== 'image/jpeg' && file.type !== 'image/bmp' && file.type !== 'image/png' && file.type !==
				'image/gif') {
				ElMessage.error('Image format error!');
				this.imageIschange = false
				return false;
			}
		},
		photoSuccess(res, file) {
			console.log(res, file)
			if (res.code == 200) {
				this.image = res.data[0];
				// this.imageIschange = true;
			} else {
				ElMessage('upload failed')
			}
		},
		photoChange() {
			this.imageIschange = true;
		},
		delPhoto() {
			this.image = '';
			this.imageIschange = false;
		},
		openPop(e, item) {
			this.popType = e;
			if (this.popType == 2) {
				this.id = item.id;
				Api.announcementInfo({
					id: this.id
				}).then(res => {
					if (res.code == 200) {
						this.title = res.data.title_en;
						this.title_ru = res.data.title;
						this.tag = res.data.tag;
						this.content = res.data.description_en;
						this.content_ru = res.data.description;
						this.image = {
							full_path: res.data.image_url,
							half_path: res.data.image
						};
						this.imageIschange = true;
					} else {
						ElMessage.error(res.message)
					}
				})
			}
			this.pop = true;
			this.bg = true;
		},
		close() {
			this.bg = false;
			this.pop = false;
			this.pop_del = false;
			this.id = "";
			this.title = "";
			this.title_ru = "";
			this.content = "";
			this.content_ru = "";
			this.tag = 1;
			this.image = "";
			this.imageIschange = false;
			this.language = "EN";
			this.comment = '';
		},
		getDate(e) {
			const date = new Date(e ? e * 1000 : '');
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = date.getHours();
			let minute = date.getMinutes();
			month = month > 9 ? month : '0' + month;
			day = day > 9 ? day : '0' + day;
			hour = hour > 9 ? hour : '0' + hour;
			minute = minute > 9 ? minute : '0' + minute;
			return `${day}.${month}.${year}<br/>${hour}:${minute}`
		},
		getList() {
			// let loadingInstance = ElLoading.service({
			// 	background: 'rgba(0, 0, 0, 0.2)',
			// 	lock: true,
			// });
			Api.announcementList({
				page: this.page,
				limit: 8
			}).then(res => {
				if (res.code == 200) {
					if (this.page == 1) {
						this.list = res.data.data;
					} else {
						for (let i in res.data.data) {
							this.list.push(res.data.data[i]);
						}
					}
					this.total = res.data.meta.pagination.total;
					this.loading = false;
					this.list.forEach((value, index) => {
						value['isMore'] = false
					})
					// this.$nextTick(() => {
					// 	loadingInstance.close();
					// });
				} else {
					ElMessage.error(res.message)
				}
			})
		}
	}
}
</script>

<style scoped>
.box {
	width: 100%;
	height: 100%;
	position: relative;
}

.con {
	text-align: left;
	columns: 2;
	column-gap: 10px;
	height: 100%;
}

.con .item {
	/* width: calc(50% - 60px); */
	width: -webkit-fill-available;
	break-inside: avoid;
	margin: 10px;
	border-radius: 20px;
	background-color: var(--background-light2);
	padding: 20px;
	display: inline-block;
	color: #fff;
}

.con .item img {
	width: 100%;
	object-fit: cover;
	margin-bottom: 10px;
	border-radius: 10px;
}

.con .item .item_top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.con .item .item_top .left {
	display: flex;
	align-items: center;
}

.con .item .item_top .left span {
	font-size: 1rem;
	color: #fff;
	margin-right: 10px;
}

.con .item .item_top .left .label {
	padding: 6px 12px;
	border-radius: 20px;
	background-color: var(--submit);
}

.con .item .item_top .left .label.purple {
	background-color: var(--text-color-purple);
}

.con .item .item_top .left .label.blue {
	background-color: var(--text-color-blue);
}

.con .item .item_top .right {
	display: flex;
}

.con .item .item_top .right .icon {
	display: inline-block;
	margin: 0 5px;
	width: 20px;
	height: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 90%;
}

.con .item .item_top .right .icon_edit {
	background-image: url(../../assets/img/table/edit.png);
}

.con .item .item_top .right .icon_del {
	background-image: url(../../assets/img/table/del.png);
}

.con .item p {
	line-height: 20px;
	font-size: 14px;
	text-align: left;
	color: var(--text-color-light);
	margin: 10px 0;
}

.con .item p span {
	color: var(--submit);
	cursor: pointer;
}

.con .item .item_bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.con .item .item_bottom .user_box {
	display: flex;
	align-items: center;
}

.con .item .item_bottom .user_box img {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	margin-right: 10px;
}

.con .item .item_bottom .user_box .name {
	line-height: 20px;
}

.con .item .item_bottom .user_box .identity {
	line-height: 20px;
	color: var(--text-color-light);
}

.con .item .item_bottom .time {
	color: var(--text-color-light0);
	text-align: right;
	line-height: 15px;
}

.pop textarea {
	height: 160px;
}

.pop .inp-box {
	margin-bottom: 10px;
}

.pop .tag_box {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.pop .tag_box .item {
	margin-right: 10px;
	border-radius: 20px;
	padding: 6px 12px;
	font-size: 14px;
}

.pop .tag_box .item.submit {
	color: var(--submit);
	border: 1px solid var(--submit);
}

.pop .tag_box .item.submit.active {
	background-color: var(--submit);
	color: var(--text-color);
}

.pop .tag_box .item.purple {
	color: var(--text-color-purple);
	border: 1px solid var(--text-color-purple);
}

.pop .tag_box .item.purple.active {
	background-color: var(--text-color-purple);
	color: var(--text-color);
}

.pop .tag_box .item.blue {
	color: var(--text-color-blue);
	border: 1px solid var(--text-color-blue);
}

.pop .tag_box .item.blue.active {
	background-color: var(--text-color-blue);
	color: var(--text-color);
}

.pop .tab-box {
	width: 100%;
	border-radius: 20px;
	background-color: var(--background-light);
	display: flex;
	align-items: center;
	font-size: 16px;
	margin-top: 10px;
}

.pop .tab-box .item {
	width: 50%;
	text-align: center;
	line-height: 35px;
	cursor: pointer;
	color: var(--text-color-light);
}

.pop .tab-box .item.active {
	background-color: var(--background-light3);
	border-radius: 20px;
	color: var(--text-color);
}

.pop .banner-box {
	position: relative;
}

.pop .banner-box img {
	width: 380px;
	height: 203px;
	border-radius: 13px;
	margin-top: 10px;
	object-fit: cover;
}

.pop .banner-box .icon {
	width: 22px;
	height: 22px;
	border-radius: 50%;
	position: absolute;
	right: 10px;
	top: 20px;
	background-color: #fff;
}

.pop .banner-box .icon .icon-del {
	width: 24px;
	height: 24px;
	background-image: url("../../assets/img/clear.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	margin: -1px;
}


.pop .addPhoto {
	border: 1px solid var(--input-border);
	border-radius: 5px;
	text-align: center;
	width: 380px;
	height: 48px;
	line-height: 48px;
	text-align: center;
	color: var(--input-border);
	font-size: 16px;
	/* margin: 20px 0; */
}
</style>