<template>
    <div class="box">
        <div class="top">
            <img src="../../assets/img/back.png" @click="back" />
        </div>
        <div class="right" v-if="other" v-loading="sendMessageLoading">
            <div class="chat-top">
                <div class="user-box">
                    <div class="img-box">
                        <img v-if="folder == 'support'" :src="other.user_avatar">
                        <img v-else :src="other.avatar">
                        <div class="green" v-if="other.user_status == 1"></div>
                    </div>
                    <div class="name-box">
                        <div class="name">{{ other.username }}</div>
                        <div class="txt">{{ other.user_status == 1 ? 'Online' : 'Offline' }}</div>
                    </div>
                </div>
            </div>

            <div>
                <el-scrollbar ref="scroll" id="chatBox" class="chat_con inner" :height="scrollbar" @scroll="scroll">
                    <ul class="inner_ul">
                        <li :class="item.sendID == other.user_im_id ? 'other' : 'me'" v-for="(item, index) in recordList"
                            :key="index">
                            <img v-if="item.sendID == other.user_im_id" :src="item.senderFaceUrl">
                            <div>
                                <div class="name">{{ item.senderNickname }}</div>
                                <p v-if="item.contentType == 101">{{ item.textElem.content }}</p>
                                <el-image  v-if="item.contentType == 102" :src="item.pictureElem.snapshotPicture.url"
                                    :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :preview-src-list="[item.pictureElem.snapshotPicture.url]"
                                    :initial-index="1" fit="cover" />
                            </div>
                            <img v-if="item.sendID != other.user_im_id" :src="item.senderFaceUrl">
                        </li>
                    </ul>
                </el-scrollbar>
            </div>
            <div class="chat-bottom">
                <div class="more">
                    <button v-if="showEnd" class="end" @click="endChat">
                        End chat
                    </button>
                    <img @click="showEnd = true" src="../../assets/img/chat/more.png">
                </div>
                <div class="file-box">
                    <img src="../../assets/img/chat/link.png">
                    <input type="file" ref="fileInput" id="uploads" accept="image/png, image/jpeg, image/gif, image/jpg"
                        @change="uploadImg">
                </div>
                <div class="inp-box">
                    <input v-model="send_text" :placeholder="isEndChat ? 'This support has ended' : 'Your message'"
                        @keyup.enter="send" :disabled="isEndChat">
                    <img src="../../assets/img/chat/smile.png">
                </div>
                <img src="../../assets/img/chat/send.png" @click="send">
            </div>

        </div>
    </div>
</template>
<script>
import Api from "../../utils/api"
import { ElMessage, ElLoading } from 'element-plus'
import { getSDK } from '../../utils/openIM';
import {
    CbEvents
} from "../../utils/openIM/constant/index.js";
import { apiAddr,wsAddr } from '../../utils/config.js'
// const OpenIM = getSDK();
export default {
    data() {
        return {
            sendMessageLoading:false,
            send_text: '',
            search_text: '',
            chatList: [],
            other: null,
            record_page: 1,
            record_total: 0,
            recordList: [],
            scrollbar: '',
            folder: '',
            showEnd: false,
            isEndChat: false,
            send_file: '',
            send_type: 101,//102img
            preview_list: [],
        }
    },
    watch: {
        folder(e) {
            // if (e == 'order') {
            //     this.getChatList();
            // } else if (e == 'support') {
            //     this.getChatList_support();
            // }
        }
    },
    mounted() {
        console.log(this.$route.query.other)
        this.folder = this.$route.query.folder;
        this.other = JSON.parse(this.$route.query.other);
        if (this.folder == 'support') {
            if (this.other.unread && this.other.unread > 0) {
                this.chatRead()
            }
        } else {
            this.chatRead()
        }

        this.getChatRecord();
        let obj = document.getElementsByClassName('box')[0];
        this.scrollbar = (obj.clientHeight - 190) + 'px';
        setTimeout(() => {
            this.getLoginStatus();
        }, 1000);
        this.listenMessage();

    },
    methods: {
        async uploadImg(e) {
            if (e.target.files.length === 0) {
                return
            }
            if (e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/bmp' && e.target
                .files[0].type !== 'image/png') {
                this.$message.error('Image format error!');
                return false;
            }
            this.send_file = e.target.files[0];
            this.send_type = 102;
            this.send();

        },
        endChat() {
            Api.supportStatus({
                support_id: this.other.id
            }).then(res => {
                if (res) {
                    if (res.code == 200) {
                        this.isEndChat = true;
                        this.showEnd = false;
                    } else {
                        ElMessage.error(res.message);
                    }
                }
            })
        },
        chatRead() {
            let data = {};
            if (this.folder == 'support') {
                data = {
                    support_id: this.other.id
                }
            } else {
                data = {
                    group_id: this.other.group_id
                }
            }
            Api.chatRead(data).then(res => {
                if (res.code == 200) {
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        back() {
            this.$router.go(-1)
        },
        getChatRecord() {
            if (this.record_page == 1) {
                this.recordList = [];
            }
            let obj
            if (this.folder == 'support') {
                obj = {
                    receive_id: this.other.user_id || this.other.email,
                    receive_type: this.other.user_type,
                    support_id: this.other.id,
                    limit: 20,
                    page: this.record_page,
                }
            } else {
                obj = {
                    group_id: this.other.group_id,
                    receive_type: this.folder == 'user' ? 2 : 1,
                    limit: 20,
                    page: this.record_page,
                }
            }
            Api.chatRecord(obj).then(res => {
                if (res.code == 200) {
                    if (res.data.data.length == 0) {
                        return;
                    }
                    for (let i in res.data.data) {
                        this.recordList.unshift(res.data.data[i]);
                    }
                    this.record_total = res.data.meta.pagination.total;
                    if (this.record_page == 1) {
                        // if (this.recordList.length > 0) {
                        //     this.chat_id = res.data.data[0].id;
                        //     console.log(this.chat_id)
                        // }
                        this.scrollBottom();
                    }
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        scrollBottom() {
            this.$nextTick(() => {
                let d = document.getElementsByClassName('inner_ul')[0];
                d.scrollIntoView(false);
            })
        },
        scroll(e) {
            if (e.scrollTop == 0 && this.recordList.length > 0) {
                console.log('scrollTop：0')
                if (this.recordList.length < this.record_total) {
                    this.record_page++;
                    this.getChatRecord();
                }
            }
        },
        listenMessage() {
            let that = this;
             this.$OpenIM.on(CbEvents.OnRecvNewMessages, ({
                data
            }) => {
                // data is the new message
                console.log('OnRecvNewMessage=========')
                console.log(data)

                data.forEach((item, index) => {
                    if (item.textElem||item.pictureElem) {
                        that.recordList.push(item)
                        that.record_total++;
                    }
                 
                })


                that.scrollBottom()
            });

        },
        loginIm() {
            //dev
            // const config = {
            // 	userID: localStorage.getItem('chat_userID'), // IM user userID
            // 	token: localStorage.getItem('chat_token'),
            // 	platformID: 5, //  1: IOS, 2: Android, 3: Windows, 4: OSX, 5: Web, 6: MiniWeb, 7: Linux, 8: Android Pad, 9: IPad, 10: admin
            // 	apiAddr: 'http://im.digitalsnail.cn:10002', // IM api address, generally `http://xxx:10002` or `https://xxx/api
            // 	wsAddr: 'ws://im.digitalsnail.cn:10001' // IM ws address, generally `ws://your-server-ip:10001`(open-im-sdk-wasm) or `ws://your-server-ip:10003`(open-im-sdk)
            // }
            // st
            const config = {
                userID: localStorage.getItem('chat_userID'), // IM user userID
                token: localStorage.getItem('chat_token'),
                platformID: 5, //  1: IOS, 2: Android, 3: Windows, 4: OSX, 5: Web, 6: MiniWeb, 7: Linux, 8: Android Pad, 9: IPad, 10: admin
                apiAddr: apiAddr, // IM api address, generally `http://xxx:10002` or `https://xxx/api
                wsAddr: wsAddr // IM ws address, generally `ws://your-server-ip:10001`(open-im-sdk-wasm) or `ws://your-server-ip:10003`(open-im-sdk)
            }
            //prod
            // const config = {
            // 	userID: localStorage.getItem('chat_userID'), // IM user userID
            // 	token: localStorage.getItem('chat_token'),
            // 	platformID: 5, //  1: IOS, 2: Android, 3: Windows, 4: OSX, 5: Web, 6: MiniWeb, 7: Linux, 8: Android Pad, 9: IPad, 10: admin
            // 	apiAddr: 'http://www.wconnect.work/imApi',
            // 	wsAddr: 'ws://www.wconnect.work/im' // IM ws address, generally `ws://your-server-ip:10001`(open-im-sdk-wasm) or `ws://your-server-ip:10003`(open-im-sdk)
            // }
             this.$OpenIM.login(config).then(res => {
                console.log(res)
                console.log("login suc...");
            }).catch(err => {
                console.log("login failed...");
                console.log(err)
                console.log("login failed...");

            })
        },
        // logOut(e) {
        //     OpenIM.logout().then(res => {
        //         Api.chatLogout().then(res => {
        //             if (res.code == 200) {
        //                 if (e == 1) { //1,logout 2,login angin
        //                     setCookie('chat_token', '')
        //                     setCookie('chat_userID', '')
        //                     setCookie('chat_expireTimeSeconds', '');
        //                 } else {
        //                     this.loginIm();
        //                 }

        //             }
        //         }).catch(err => {

        //         })
        //         console.log("loginOut suc...");
        //     }).catch(err => {
        //         console.log("loginOut failed...");
        //         console.log(err);
        //         setCookie('chat_token', '')
        //         setCookie('chat_userID', '')
        //         setCookie('chat_expireTimeSeconds', '');
        //     })
        // },

        getLoginStatus() {
             this.$OpenIM.getLoginStatus().then(({
                data
            }) => {
                console.log(data)
                // if (data !== 3 && data !==2) {
                //     this.loginIm();
                // }
                // if (data == 1) {
                //     this.loginIm();
                // }
                // data: LoginStatus
            }).catch(({
                errCode,
                errMsg
            }) => {
                console.log(errCode, errMsg);
                // logOut(2);
                // Call failed
                //this.loginIm();
            });
        },
        getChatList_support() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.chatSupport({

            }).then(res => {
                if (res.code == 200) {
                    this.chatList = res.data.data;
                    if (this.chatList.length > 0) {
                        this.other = res.data.data[0];

                        let obj = document.getElementsByClassName('box')[0];
                        this.scrollbar = (obj.clientHeight - 140) + 'px';
                    }
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                    ElMessage.error(res.message)
                }

            })
        },
        getChatList() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.chatList({
                group_id: this.$route.query.group_id || null
            }).then(res => {
                if (res) {
                    if (res.code == 200) {
                        this.chatList = res.data;
                        if (this.chatList.length > 0) {

                            let obj = document.getElementsByClassName('inner')[0];
                            this.scrollbar = obj.clientHeight + 'px';
                            this.other = res.data[0]
                        }
                    } else {
                        ElMessage.error(res.message)
                    }
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })

        },
        search() {
            console.log('search:', this.search_text)
        },
        send_private() {
            this.sendMessageLoading = true;
            let params;
            if (this.send_type == 102) {
                params = new FormData()
                params.append("image", this.send_file);
                params.append("content_type", this.send_type);
                params.append("receive_id", this.other.user_id || this.other.email);
                params.append("receive_type", this.other.user_type);
                params.append("support_id", this.folder == 'support' ? this.other.id : null);
            } else {
                params = {
                    content_type: this.send_type,
                    receive_id: this.other.user_id || this.other.email,
                    receive_type: this.other.user_type,
                    send_message: this.send_text,
                    support_id: this.folder == 'support' ? this.other.id : null,
                    // order_id: ''
                }
            }
            Api.chatSendPrivate(params).then(res => {
                if (res.code == 200) {
                    this.send_text = '';
                    this.send_file = '';
                    this.send_type = 101;
                    this.scrollBottom();
                   this.sendMessageLoading = false;
                } else {
                    ElMessage.error(res.message)
                   this.sendMessageLoading = false;
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        send_group() {
            this.sendMessageLoading = true;
            let params;
            if (this.send_type == 102) {
                params = new FormData()
                params.append("image", this.send_file);
                params.append("content_type", this.send_type);
                params.append("group_id", this.other.group_id);
            } else {
                params = {
                    content_type: this.send_type,
                    send_message: this.send_text,
                    group_id: this.other.group_id,
                    // order_id: this.chat_info.order_id
                }
            }
            Api.chatSendGroup(params).then(res => {
                if (res.code == 200) {
                    this.send_text = '';
                    this.send_file = '';
                    this.send_type = 101;
                    this.scrollBottom();
                    this.sendMessageLoading = false;
                } else {
                    ElMessage.error(res.message)
                    this.sendMessageLoading = false;
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        send() {
            if (this.isEndChat) {
                return
            }
            if (this.send_text||this.send_file) {
                if (this.folder == "support") {
                    this.send_private();
                } else {
                    this.send_group();
                }

            }

        },
        getDate(e) {
            const date = new Date(e ? e * 1000 : '');
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            month = month > 9 ? month : '0' + month;
            day = day > 9 ? day : '0' + day;
            hour = hour > 9 ? hour : '0' + hour;
            minute = minute > 9 ? minute : '0' + minute;
            return `${hour}:${minute}`
        }
    },
}
</script>
<style>
.el-image-viewer__close {
    background-color: var(--background-light3);
}

.el-image-viewer__actions {
    background-color: var(--background-light3);
}

.box .right .inner ul li .el-image img {
    border-radius: 0px;
    border: 0;
    margin-top: 5px;
    cursor: pointer;
    width: auto;
    height: auto;
    object-fit: cover;
    max-width: 200px;
    max-height: 200px;
}
</style>

<style scoped>
.box {
    height: 100%;
}

.box .top {
    width: 30px;
    height: 30px;
    border: 1px solid var(--submit);
    border-radius: 50%;
    margin-bottom: 20px;
    margin-left: 20px;
}

.box .top img {
    width: 25px;
    height: 25px;
    margin: 2.5px;
    object-fit: cover;
    cursor: pointer;
}



.box .right {
    width: calc(100%);
    /* margin-left: 20px; */
    border-radius: 20px;
    height: calc(100% - 50px);
    background-color: var(--background-light2);
}

.box .right .chat-top {
    border-bottom: 1px solid var(--img-border);
    padding: 10px 15px;
    height: 40px;
}

.box .right .chat-top .user-box {
    display: flex;
}

.box .right .chat-top .user-box .img-box {
    position: relative;
    margin-right: 5px;
}

.box .right .chat-top .user-box img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--img-border);
}

.box .right .chat-top .user-box .green {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 0px;
    background-color: var(--text-color-green);
}

.box .right .chat-top .user-box .name-box {
    text-align: left;
}

.box .right .chat-top .user-box .name-box .name {
    color: var(--text-color);
    font-size: 14px;
    line-height: 20px;
}

.box .right .chat-top .user-box .name-box .txt {
    color: var(--text-color-light);
    font-size: 12px;
    line-height: 20px;
    font-weight: 100;
}

.box .right .inner {
    padding: 10px 15px;
    width: calc(100% - 30px);
    height: calc(100vh - 300px);
}


.box .right .inner ul li {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    width: 100%;
}

.box .right .inner ul li.other {
    justify-content: flex-start;
}

.box .right .inner ul li.other img {
    margin: 0 2px 0 0;
}

.box .right .inner ul li img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 0 0 2px;
    border: 1px solid var(--img-border);
}
.box .right .inner ul li .el-image {
    max-width: none;
}

.box .right .inner ul li div .name {
    color: var(--text-color);
    font-weight: bold;
    text-align: left;
    padding: 0;
    font-size: 14px;
}

.box .right .inner ul li div p {
    color: var(--text-color-light);
    line-height: 16px;
    text-align: left;
    word-wrap: break-word;
}

.box .right .inner ul li div {
    padding: 5px 8px;
    border-radius: 5px;
    background-color: var(--background-light);
    max-width: calc(100% - 60px)
}

.box .right .inner ul li.me div {
    background-color: var(--background-blue);


}

.box .right .chat-bottom {
    display: flex;
    padding: 10px 15px;
    height: 40px;
    border-top: 1px solid var(--img-border);
    display: flex;
    align-items: center;

}

.box .right .chat-bottom .more {
    position: relative;
}

.box .right .chat-bottom .more:hover .end {
    /* display: block; */
}
.box .right .chat-bottom .file-box {
    position: relative;
    cursor: pointer;
}

.box .right .chat-bottom .file-box input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;

}
.box .right .chat-bottom .end {
    /* display:none; */
    left: -10px;
    width: 100px;
    top: -75px;
    position: absolute;
    background: var(--background-light);
    padding: 20px;
    clip-path: polygon(15px 0px, calc(100% - 15px) 0, 100% 15px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 calc(100% - 15px), 0 15px);
    color: var(--text-color);
}

.box .right .chat-bottom img {
    height: 18px;
    object-fit: cover;
    margin: 0 10px;
    cursor: pointer;
}

.box .right .chat-bottom .inp-box {
    width: 100%;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    padding: 5px 10px;
    padding-right: 0;
    height: 20px;
    display: flex;
}

.box .right .chat-bottom .inp-box input {
    width: calc(100% - 20px);
    line-height: 20px;
    color: var(--text-color);
}
</style>