<template>
    <div class="queue_box box" v-if="message != null">
        <div class="tit-box">
            <div class="num">1</div>
            <div class="txt">Start screen</div>
        </div>
        <div class="con">
            <div class="tit">Start message</div>
            <div class="border">
                <p>{{ lang == 'En' ? message.start_message.content_en : message.start_message.content_ru }}</p>
                <div class="flex-bottom">
                    <div class="icon_edit" @click="openPop_edit0(message.start_message)"></div>
                    <el-switch v-model="message.start_message.status" class="ml-2" :active-value="1" :inactive-value="0"
                        @change="change(message.start_message)"
                        style="--el-switch-on-color: var(--background-light); --el-switch-off-color:var(--background-light2)" />
                </div>
            </div>
        </div>
        <div class="tit-box">
            <div class="num">2</div>
            <div class="txt">Wait screen</div>
        </div>
        <div class="con" style="margin-right: 20px;">
            <div class="tit">Wait message</div>
            <div class="border">
                <p>{{ lang == 'En' ? message.wait_message.content_en : message.wait_message.content_ru }}</p>
                <div class="flex-bottom">
                    <div class="icon_edit" @click="openPop_edit0(message.wait_message)"></div>
                    <el-switch v-model="message.wait_message.status" class="ml-2" :active-value="1" :inactive-value="0"
                        @change="change(message.wait_message)"
                        style="--el-switch-on-color: var(--background-light); --el-switch-off-color:var(--background-light2)" />
                </div>
            </div>
        </div>
        <div class="con">
            <div class="flex">
                <div class="tit">Auto accept</div>
                <el-switch v-model="message.auto_accept.switch" class="ml-2" :active-value="1" :inactive-value="0"
                    @change="change_switch(message.auto_accept)"
                    style=" margin-bottom: -10px;--el-switch-on-color: var(--submit); --el-switch-off-color:var(--background-light2)" />
            </div>

            <div class="border">
                <p>{{ lang == 'En' ? message.auto_accept.content_en : message.auto_accept.content_ru }}</p>
                <div class="flex-bottom">
                    <div class="icon_edit" @click="openPop_edit0(message.auto_accept)"></div>
                    <el-switch v-model="message.auto_accept.status" class="ml-2" :active-value="1" :inactive-value="0"
                        @change="change(message.auto_accept)"
                        style="--el-switch-on-color: var(--background-light); --el-switch-off-color:var(--background-light2)" />
                </div>
            </div>
        </div>

        <div class="tit-box">
            <div class="num">3</div>
            <div class="txt">Settings</div>
        </div>
        <ul>
            <li>
                <div class="flex">
                    <div class="txt">Maximum queue number</div>
                    <div class="input">{{
                        lang == 'En' ? message.maximum_queue_number.content_en : message.maximum_queue_number.content_ru }}</div>
                    <div class="edit-icon" @click="openPop_edit1(message.maximum_queue_number)"></div>
                </div>

            </li>
            <li>
                <div class="flex">
                    <div class="txt">Number of skiped orders</div>
                    <div class="input">{{
                        lang == 'En' ? message.number_of_skiped_orders.content_en : message.number_of_skiped_orders.content_ru }}
                    </div>
                    <div class="edit-icon" @click="openPop_edit1(message.number_of_skiped_orders)"></div>
                </div>

            </li>
            <!-- <li>
                <div class="flex">
                    <div class="txt">Ban for cancellation</div>
                    <div class="input">{{
                        lang == 'En' ? message.ban_for_cancellation.content_en : message.ban_for_cancellation.content_ru }} h.</div>
                    <div class="edit-icon" @click="openPop_edit1(message.ban_for_cancellation)"></div>
                </div>

            </li> -->
            <li>
                <div class="flex">
                    <div class="txt">Time to think</div>
                    <div class="input">{{ lang == 'En' ? message.time_to_think.content_en : message.time_to_think.content_ru }} min.
                    </div>
                    <div class="edit-icon" @click="openPop_edit1(message.time_to_think)"></div>
                </div>
            </li>
            <li>
                <div class="flex">
                    <div class="txt">Ban for skipping</div>
                    <div class="input">{{ lang == 'En' ? message.ban_for_skipping.content_en : message.ban_for_skipping.content_ru
                    }} h.</div>
                    <div class="edit-icon" @click="openPop_edit1(message.ban_for_skipping)"></div>
                </div>
            </li>
        </ul>

        <div class="pop-bg" v-if="bg" @click="close()">

            <div class="pop_box" v-if="pop_edit0" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Edit
                    </div>
                    <!-- <div class="inp-box">
                        <div class="text-gray left">Language</div>
                        <div class="tab-box">
                            <div class="item" :class="language == 'EN' ? 'active' : ''" @click="language = 'EN'">EN</div>
                            <div class="item" :class="language == 'RU' ? 'active' : ''" @click="language = 'RU'">RU</div>
                        </div>
                    </div> -->
                    <div class="inp-box">
                        <textarea v-if="language == 'EN'" v-model="content_en"></textarea>
                        <!-- <textarea v-if="language == 'RU'" v-model="content_ru"></textarea> -->
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">Cancel</button>
                        <button class="submit" @click="save0">Save</button>
                    </div>
                </div>
            </div>
            <div class="pop_box" v-if="pop_edit1" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Edit
                    </div>
                    <!-- <div class="inp-box">
                        <div class="text-gray left">Language</div>
                        <div class="tab-box">
                            <div class="item" :class="language == 'EN' ? 'active' : ''" @click="language = 'EN'">EN</div>
                            <div class="item" :class="language == 'RU' ? 'active' : ''" @click="language = 'RU'">RU</div>
                        </div>
                    </div> -->
                    <div class="inp-box">
                        <div class="text-gray left" v-if="key=='maximum_queue_number'">Maximum queue number</div>
                        <div class="text-gray left" v-if="key=='number_of_skiped_orders'">Number of skiped orders</div>
                        <div class="text-gray left" v-if="key=='ban_for_cancellation'">Ban for cancellation( h.)</div>
                        <div class="text-gray left" v-if="key=='time_to_think'">Time to think( min.)</div>
                        <div class="text-gray left" v-if="key=='ban_for_skipping'">Ban for skipping( h.)</div>
                        <input v-model="title" placeholder="Title" />
                        <!-- <input v-if="language == 'RU'" v-model="title_ru" placeholder="Title" /> -->
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">Cancel</button>
                        <button class="submit" @click="save1">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ElMessage, ElLoading } from 'element-plus'
import Api from '../../utils/api.js';
export default {
    data() {
        return {
            start_status: 0,
            wait_status: 0,
            auto_status: 0,
            auto_switch: 0,
            bg: false,
            pop_edit0: false,
            pop_edit1: false,
            content_en: '',
            content_ru: '',
            // title_en: '',
            // title_ru: '',
            title:'',
            lang: "En",
            language: 'EN',
            message: null,
            key: ''
        }
    },
    mounted() {
        this.lang = localStorage.getItem('lang');
        this.getMessage();

    },

    methods: {
        getMessage() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.queueSettingList().then(res => {
                if (res.code == 200) {
                    this.message = res.data;
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message)
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        },
        queueSettingEdit(data) {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.queueSettingEdit(data).then(res => {
                if (res.code == 200) {
                    this.close();
                    this.getMessage();
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message)
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        },
        change(item) {
            this.key = item.key;
            let data = {
                key: this.key,
                status: item.status,
            }
            this.queueSettingEdit(data);
        },
        change_switch(item) {
            this.key = item.key;
            let data = {
                key: this.key,
                switch: item.switch,
            }
            this.queueSettingEdit(data);
        },
        openPop_edit0(item) {
            this.bg = true;
            this.pop_edit0 = true;
            this.key = item.key;
            this.content_en = item.content_en;
            this.content_ru = item.content_ru;
        },
        openPop_edit1(item) {
            this.bg = true;
            this.pop_edit1 = true;
            this.key = item.key;
            this.title = item.content_en;
            this.title = item.content_ru;
        },
        close() {
            this.bg = false;
            this.pop_edit0 = false;
            this.pop_edit1 = false;
            this.content_en = '';
            this.content_ru = '';
            this.title_en = '';
            this.title_ru = '';
            this.key = '';
            this.language = 'EN'
        },
        save0() {
            if (this.content_en != '' && this.content_ru != '') {
                let data = {
                    key: this.key,
                    content_en: this.content_en,
                    content_ru: this.content_ru,
                }
                this.queueSettingEdit(data);
            } else {
                ElMessage('Please complete the information.');
                return;
            }
        },
        save1() {
            if (this.title != '') {
                let data = {
                key:this.key,
                content_en:this.title,
                content_ru:this.title,
            }
            this.queueSettingEdit(data);
            } else {
                ElMessage('Please complete the information.');
                return;
            }
        }
    }
}
</script>
<style >
.queue_box .flex-bottom .el-switch__core {
    border-radius: 3px;
    border: 1px solid var(--img-border) !important;
}

.queue_box .flex-bottom .el-switch__core .el-switch__action {
    background-color: var(--text-color-purple);
    border-radius: 3px;
}
</style>
<style scoped>
.box {
    padding: 30px;
    text-align: left;
}

.box .tit-box {
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-size: 18px;
}

.box .tit-box .num {
    color: var(--text-color);
    border-radius: 50%;
    background-color: var(--submit);
    padding: 3px 7px;
    font-size: 14px;
    margin-right: 10px;
}

.box .con {
    width: calc(50% - 20px);
    color: var(--text-color);
    text-align: left;
    margin-bottom: 30px;
    display: inline-block;
}

.box .con .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box .con .tit {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 8px;
}

.box .con .border {
    border: 1px solid var(--img-border);
    border-radius: 5px;
    padding: 8px;
}

.box .con .border p {
    color: var(--text-color-light);
    line-height: 15px;
}

.box .con .border .flex-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.box .con .border .flex-bottom .icon_edit {
    width: 20px;
    height: 20px;
    background-image: url(../../assets/img/table/edit.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    margin-right: 5px;
    cursor: pointer;
}

.box ul li {
    width: 27%;
    display: inline-block;
    margin: 10px 3%;
    color: var(--text-color);
    font-size: 12px;
}

.box ul li .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.box ul li .input {
    border: 1px solid var(--img-border);
    border-radius: 5px;
    padding: 5px;
    color: var(--input-border);
    width: 50px;
    margin: 0 10px;
    height: 15px;
    font-size: 12px;
}

.box ul li .edit-icon {
    width: 20px;
    height: 20px;
    background-image: url(../../assets/img/table/edit.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    cursor: pointer;
}

.pop .tab-box {
    width: 100%;
    border-radius: 20px;
    background-color: var(--background-light);
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 10px;
}

.pop .tab-box .item {
    width: 50%;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    color: var(--text-color-light);
}

.pop .tab-box .item.active {
    background-color: var(--background-light3);
    border-radius: 20px;
    color: var(--text-color);
}

.pop .inp-box textarea {
    height: 150px !important;
}</style>