<template>
	<div class='sidebar'>
		<!-- <el-menu class="top-menu" style="margin-bottom: 10px;" default-active="0" router :unique-opened='true'>
			<el-menu-item class="menu-item" index='/dashboard'
				:class="$route.path.split('/')[1]=='dashboard'?'is-active':'no-active'">
				<span>General settings</span>
			</el-menu-item>
		</el-menu> -->
		<div class="logo_box">
			<img src="../../assets/img/menu/logo.png" />
		</div>

		<el-menu class="menu second" default-active="0" router :unique-opened='true'>
			<div v-for="(item, index) in menu" :key='index'>
				<el-sub-menu class="menu-item" :index='item.path' v-if="item.children.length > 0"
					:class="$route.path.split('/')[1] == item.path.split('/')[1] ? 'is-opened' : ''">
					<template #title>
						<img class="border_left" src="../../assets/img/menu/border_left.png">
						<div class="border"></div>
						<img class="img_0" :src="item.icon_0" />
						<img class="img_1" :src="item.icon_1" />
						<span style="width: 100%;text-align: left;" @click='chkMenu(item)'>
							{{ item.name }}
						</span>

					</template>
					<el-menu-item-group>
						<el-menu-item style="font-weight: 360;margin:0;" class="menu-item"
							:class="$route.path.split('/')[2] == itm.path.split('/')[2] ? 'is-active' : 'no-active'"
							:index="itm.path" v-for="(itm, indx) in item.children" :key="indx">
							<div class="txt_box">

								<img src="../../assets/img/menu/menu_item_border.png" />
								<div class="txt">{{ itm.name }}</div>
							</div>


						</el-menu-item>

					</el-menu-item-group>
				</el-sub-menu>
				<el-menu-item class="menu-item menu-frist" v-else :index='item.path'
					:class="$route.path.split('/')[1] == item.path.split('/')[1] ? 'is-opened' : ''">
					<template #title>
						<img class="border_left" src="../../assets/img/menu/border_left.png">
						<div class="border"></div>
						<img style="height: 14px;" class="img_0" :src="item.icon_0" />
						<img style="height: 14px;" class="img_1" :src="item.icon_1" />
						<span style="width: 100%;text-align: left;" @click='chkMenu(item)'>
							{{ item.name }}
						</span>
						<div v-if="item.message>0" class="message">{{ item.message }}</div>
					</template>
				</el-menu-item>
			</div>
		</el-menu>
		<div class="line"></div>
		<ul class="online">
			<li>
				<div>User online</div>
				<span class="num">{{ online }}</span>
			</li>
			<li>
				<div>User offline</div>
				<span class="num">{{ offline }}</span>
			</li>
		</ul>
		<div class="line"></div>
		<el-menu class="menu second" default-active="0" router :unique-opened='true'>
			<el-menu-item class="second-menu" :index="item.path"
				:class="$route.path.split('/')[1] == item.path.split('/')[1] ? 'menu_active' : ''"
				v-for="(item, index) in menu_second" :key="index">
				<!-- <div class="border"></div> -->
				<img class="active_icon" :src="item.icon_0" />
				<img class="icon" :src="item.icon_1" />
				<span v-html="item.name"></span>

			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
import { ElMessage } from 'element-plus'
import Api from '../../utils/api.js'
export default {
	data() {
		return {
			online: '',
			offline: '',
			menu: [{
				name: 'Dashboard',
				id: 0,
				message: 0,
				path: '/dashboard',
				icon_0: require('../../assets/img/menu/dashboard_0.png'),
				icon_1: require('../../assets/img/menu/dashboard_1.png'),
				children: [{
					name: 'User',
					id: 0,
					message: 0,
					path: '/dashboard/index',

				}, {
					name: 'Booster',
					id: 0,
					message: 0,
					path: '/dashboard/booster',

				},
				{
					name: 'Job',
					id: 0,
					message: 0,
					path: '/dashboard/job',

				},
				{
					name: 'Administrators',
					id: 0,
					message: 0,
					path: '/dashboard/administrators',

				},
				]
			},
			{
				name: 'Orders',
				id: 0,
				message: 0,
				path: '/order',
				icon_0: require('../../assets/img/menu/orders_0.png'),
				icon_1: require('../../assets/img/menu/orders_1.png'),
				children: [{
					name: 'Rank Boosting',
					id: 0,
					message: 0,
					path: '/order/rank_boosting',

				}, {
					name: 'Placement Matches',
					id: 0,
					message: 0,
					path: '/order/placement_matches',

				}, {
					name: 'Unrated Games',
					id: 0,
					message: 0,
					path: '/order/unrated_games',

				}, {
					name: 'Ranked Wins',
					id: 0,
					message: 0,
					path: '/order/ranked_wins',

				}, {
					name: 'Account Leveling',
					id: 0,
					message: 0,
					path: '/order/account_leveling',

				},]
			},
			{
				name: 'Statistic',
				id: 0,
				message: 0,
				path: '/statistic',
				icon_0: require('../../assets/img/menu/financial_reports.png'),
				icon_1: require('../../assets/img/menu/financial_reports_0.png'),
				children: [{
					name: 'Financial reports',
					id: 0,
					message: 0,
					path: '/statistic/financial_reports',

				},
				]
			},
			{
				name: 'Calculator',
				id: 0,
				message: 0,
				path: '/calculator',
				icon_0: require('../../assets/img/menu/calculator.png'),
				icon_1: require('../../assets/img/menu/calculator_0.jpg'),
				children: []
			},
			{
				name: 'Promocode',
				id: 0,
				message: 0,
				path: '/promocode',
				icon_0: require('../../assets/img/menu/promocode_0.png'),
				icon_1: require('../../assets/img/menu/promocode.png'),
				children: []
			},
			{
				name: 'Support',
				id: 0,
				message: 0,
				path: '/support',
				icon_0: require('../../assets/img/menu/support.png'),
				icon_1: require('../../assets/img/menu/support_0.png'),
				children: []
			},
			{
				name: 'Queue settings',
				id: 0,
				message: 0,
				path: '/queue_settings',
				icon_0: require('../../assets/img/menu/queue.png'),
				icon_1: require('../../assets/img/menu/queue_0.png'),
				children: []
			},
			{
				name: 'Access settings',
				id: 0,
				message: 0,
				path: '/access_settings',
				icon_0: require('../../assets/img/menu/access.png'),
				icon_1: require('../../assets/img/menu/access_0.png'),
				children: []
			},

			]
			,
			menu_second: [
				{
					name: 'Announcement <br /> dashboard',
					id: 0,
					message: 0,
					path: '/announcement_dashboard',
					icon_0: require('../../assets/img/news.png'),
					icon_1: require('../../assets/img/news_gray.png'),
				},
				{
					name: 'Faq',
					id: 0,
					message: 0,
					path: '/faq',
					icon_0: require('../../assets/img/menu/faq_0.png'),
					icon_1: require('../../assets/img/menu/faq_1.png'),
				},
				{
					name: 'Privacy Policy',
					id: 0,
					message: 0,
					path: '/privacy_policy',
					icon_0: require('../../assets/img/menu/privacyPolicy_0.png'),
					icon_1: require('../../assets/img/menu/privacyPolicy_1.png'),
				},
				{
					name: 'Terms Conditions',
					id: 0,
					message: 0,
					path: '/terms_conditions',
					icon_0: require('../../assets/img/menu/termsConditions_0.png'),
					icon_1: require('../../assets/img/menu/termsConditions_1.png'),
				},
				{
					name: 'GoBoost Fine',
					id: 0,
					message: 0,
					path: '/goboost_fine',
					icon_0: require('../../assets/img/menu/fine_0.png'),
					icon_1: require('../../assets/img/menu/fine_1.png'),
				},
				{
					name: 'GoBoost Rules',
					id: 0,
					message: 0,
					path: '/goboost_rules',
					icon_0: require('../../assets/img/menu/rule_0.png'),
					icon_1: require('../../assets/img/menu/rule_1.png'),
				}
			]
		}
	},
	mounted() { },
	created() {
		this.getOnline();
		this.getMenu();
	},
	methods: {
		getImgUrl(img) {
			return require('../../assets/img/menu/' + img);
		},
		getMenu() {
			this.menu = [];
			this.menu_second = [];
			Api.indexMenu().then(res => {
				if (res) {
					if (res.code == 200) {
						this.menu = res.data.menu;
						for (let i in this.menu) {
							this.menu[i].icon_0 = require('../../assets/img/menu/' + this.menu[i].icon_0);
							this.menu[i].icon_1 = require('../../assets/img/menu/' + this.menu[i].icon_1);
						}
						this.menu_second = res.data.menu_second;
						for (let i in this.menu_second) {
							this.menu_second[i].icon_0 = require('../../assets/img/menu/' + this.menu_second[i].icon_0);
							this.menu_second[i].icon_1 = require('../../assets/img/menu/' + this.menu_second[i].icon_1);
						}
					} else {
						ElMessage.error(res.message)
					}
				}
			})
		},
		getOnline() {
			Api.indexOnline().then(res => {
				if (res) {
					if (res.code == 200) {
						this.online = res.data.online;
						this.offline = res.data.offline;
					} else {
						ElMessage.error(res.message)
					}
				}
			})
		},
		chkMenu(item) {
			if (this.$route.path.split('/')[1] == item.path.split('/')[1]) {
				return
			}
			if (item.children.length > 0) {
				this.$router.push({
					path: item.children[0].path
				})
			} else {
				this.$router.push({
					path: item.path
				})
			}

		}
	},
}
</script>

<style lang='less'>
.sidebar {
	border-radius: 10px;
	overflow: hidden;

	.message {
		color: var(--text-color-light);
		background-color: var(--background-light2);
		border-radius: 50%;
		height: 20px;
		line-height: 20px;
		padding: 5px 10px;
	}

	/* height: 100%; */
	.logo_box {
		>img {
			height: 46px;
			margin: 30px auto 20px auto;
		}
	}

	.line {
		width: calc(100% - 40px);
		border: none;
		height: 1px;
		background-color: var(--background-light2);
		margin: 20px;
	}
}


.el-menu {
	background-color: transparent !important;
	border-right: none !important;
}

.el-sub-menu__title,
.el-menu-item {
	overflow: hidden !important;

	>span {
		color: var(--text-color-light) !important;
	}

	>img {
		margin-right: 10px;
		height: 14px;
	}

	.img_1 {
		display: none;
	}

	.border_left {
		display: none;
		height: 20px;
		margin-right: 30px;
	}

	.border {
		display: inline-block;
		height: 20px;
		width: 5px;
		margin-right: 30px;
	}
}

.el-menu-item:hover,
.el-sub-menu__title:hover {
	overflow: hidden !important;

	>span {
		color: var(--text-color) !important;
	}


	.img_1 {
		display: inline-block;
	}

	.img_0 {
		display: none;
	}
}

.el-menu-item-group {
	>ul {
		>li {
			color: var(--text-color-light) !important;
			overflow: hidden;
		}
	}
}

.el-sub-menu.is-opened {
	background-color: transparent !important;

	>ul {
		/* display:block !important; */
	}

	.el-sub-menu__title {
		>span {
			color: var(--text-color) !important;
		}
	}

	.border_left {
		display: inline-block;
		height: 20px;
		margin-right: 30px;
	}

	.border {
		display: none;

	}

	.img_1 {
		display: inline-block;
	}

	.img_0 {
		display: none;
	}
}

.el-menu-item {
	>span {
		line-height: 20px;
		text-align: left;
	}

	>img {
		width: 20px;
		height: 20px;
		object-fit: contain;
	}

	.active_icon {
		display: none;
	}
}

.menu-frist.is-opened {
	>span {
		color: var(--text-color) !important;
	}

	.border_left {
		display: inline-block;
		// margin-right: -5px;
		width: auto;
	}

	.border {
		display: none;

	}

	.img_1 {
		display: inline-block;
	}

	.img_0 {
		display: none;
	}
}


.el-sub-menu__title:hover,
.el-menu-item:hover {
	color: var(--text-color) !important;
	background-color: transparent !important;

	.active_icon {
		display: inline-block;
	}

	.icon {
		display: none;
	}
}

.second-menu {
	border-radius: 24px !important;
	width: 75% !important;
	margin: auto !important;
}

.el-menu-item.menu_active {
	border-radius: 24px !important;
	background-color: var(--text-color-purple) !important;
	width: 75% !important;
	margin: auto !important;


	>span {
		color: var(--text-color) !important;
	}


	.icon {
		display: none;
	}

	.active_icon {
		display: inline-block;
	}

	.border {
		display: none;
	}
}


.el-sub-menu .el-menu-item {
	padding-left: 50px !important;

	.txt_box {
		border-left: 1px solid var(--border);
		padding: 0 20px;
		width: calc(100% - 40px);
		position: relative;
		height: 100%;

		>img {
			width: 12px;
			position: absolute;
			left: -1px;
			bottom: 20px;
		}
	}

	.txt {
		padding: 0px 16px;
		width: calc(100% - 20px);
		text-align: left;
		background-color: transparent !important;
		border-radius: 30px;
		height: 40px !important;
		line-height: 40px !important;
	}
}

.el-sub-menu .is-active {
	.txt {
		background-color: var(--background-light2) !important;
		color: var(--text-color) !important;
	}
}

.el-sub-menu .no-active {
	.txt {
		background-color: transparent !important;
		color: var(--text-color-light) !important;
	}
}

.online li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 60%;
	margin: auto;
	margin-bottom: 10px;

}

.online li div {
	color: var(--text-color-light);
	font-size: 14px;
}

.online li span {
	color: var(--text-color);
	background-color: var(--background-light2);
	border-radius: 20px;
	padding: 5px 10px;
	display: inline-block;
}
</style>