<template>
	<div class="box">
		<div class="top">
			<ul class='left'>
				<li>
					<span>Job</span>
					<div class="num">{{total}}</div>
				</li>
			</ul>

			<div class="right">
				<div class="search_box">
					<input v-model="search" placeholder="Search by keywords" @keyup.enter="goSearch">
					<div class="icon icon_search" @click="goSearch"></div>
				</div>

			</div>
		</div>
		<div class="con-table">
			<table>
				<thead>
					<tr>
						<th style="width: 4%;">Info</th>
						<th style="width: 10%;">Position</th>
						<th style="width: 10%;">Nick name</th>
						<th style="width: 10%;">Email</th>
						<th style="width: 8%;">Region</th>
						<th style="width: 12%;">Highest division</th>
						<th style="width: 10%;">Discord</th>
						<th style="width: 9%;">Date</th>
						<th style="width: 10%;">Status</th>
						<th style="width: 14%;">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item,index) in list" :key="index" :class="index%2===0?'odd':'even'">
						<td class="icon_box">
							<div class="icon icon_info"></div>
						</td>
						<td>{{item.position_type==1?'Booster':item.position_type==2?'Coach':'Live chat support'}}</td>
						<td>{{item.game_nickname}}</td>
						<td>{{item.personal_email}}</td>
						<td>{{item.region}}</td>
						<td class="icon_box">
							<img :src="item.game_highest_division_photo" />
							<span>{{item.game_highest_division_name}}</span>
						</td>
						<td>{{item.personal_discord}}</td>
						<td>{{getDate(item.created_at)}}</td>
						<td class="txt_box">
							<div class="red_txt" v-if="item.status==2">
								Review failed
								<div class="icon icon_red_warn"></div>
							</div>
							<div class="pink_txt" v-if="item.status==0">
								Pending review
								<!-- <div class="icon icon_pink_warn"></div> -->
							</div>
							<div class="green_txt" v-if="item.status==1">
								Review passed
							</div>
						</td>
						<td class="icon_box" v-if="item.status==0">
							<div class="iconBG" @click="openPop(1,item)">
								<div class="icon icon_yes"></div>
							</div>
							<div class="iconBG" @click="openPop(2,item)">
								<div class="icon icon_no"></div>
							</div>
						</td>
						<td class="icon_box submit" v-if="item.status==1">
							<div class="iconBG sub" >
								<div class="icon icon_yes"></div>
							</div>
						</td>
						<td class="icon_box submit" v-if="item.status==2">
							<div class="iconBG can">
								<div class="icon icon_no"></div>
							</div>
						</td>

					</tr>
				</tbody>
			</table>

		</div>
		<div class="pop-bg" v-if="bg" @click="close()">
			
			<div class="pop_box" v-if="pop" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						Review
					</div>
					<div class="text-gray left">
						{{popType==1?'Please confirm that this job is approved.':'Please confirm to reject this job review'}}
					</div>
					<div class="inp-box">
						<textarea v-model="comment" ></textarea>
					</div>
					<div class="button-box">
						<button class="cancel" @click="close">No</button>
						<button class="submit" @click="review">Yes</button>
					</div>
				</div>
				
			</div>
		</div>
		<pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
			@backPage='backPage'></pagination>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import Api from '../../../utils/api.js';
	import pagination from '../../common/pagination.vue';
	export default {
		components: {
			pagination
		},
		data() {
			return {
				search: '',
				pageSize: 8,
				nowPage: 1,
				total: 0,
				list: [],
				bg:false,
				pop:false,
				popType:1,
				comment:'',
				jobId:'',
			}

		},
		created() {

		},
		mounted() {
			let table = document.getElementsByClassName('con-table')[0];
			this.pageSize = parseInt((table.clientHeight - 50) / 50);
			this.getList();
		},
		methods: {
			openPop(e,item){
				this.popType = e
				this.pop = true;
				this.bg = true;
				this.jobId = item.id
			},
			close(){
				this.pop = false;
				this.bg = false;
				this.comment = '';
			},
			review(){
				if(this.comment==''){
					ElMessage('Please complete the comment.');
					return;
				}
				Api.jobOperation({
					id:this.jobId,
					type:this.popType,
					comment:this.comment,
				}).then(res=>{
					if (res.code == 200) {
						this.getList();
						this.close();
					} else {
						ElMessage.error(res.message)
					}
				})
			},
			getDate(e) {
				const date = new Date(e ? e * 1000 : '');
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				let hour = date.getHours();
				let minute = date.getMinutes();
				month = month > 9 ? month : '0' + month;
				day = day > 9 ? day : '0' + day;
				hour = hour > 9 ? hour : '0' + hour;
				minute = minute > 9 ? minute : '0' + minute;
				return `${day}/${month}/${year}`
			},
			goSearch() {
				this.nowPage = 1;
				this.getList();
			},
			change_booster(e, item, index) {
				console.log(e, item);
				item.booster_name_choose = e;
				this.choose_booster_index = index;
			},
			getList() {
				Api.jobApply({
					limit: this.pageSize,
					search: this.search,
				}).then(res => {
					if (res.code == 200) {
						this.list = res.data.data;
						this.total = res.data.meta.pagination.total;
					} else {
						ElMessage.error(res.message)
					}
				})
			},
			goPage(e) {
				this.nowPage = e
				this.getList();
				console.log('nowPage:', this.nowPage)
			},
			nextPage(e) {
				this.nowPage = e
				this.getList();
				console.log('nowPage:', this.nowPage)
			},
			backPage(e) {
				this.nowPage = e
				this.getList();
				console.log('nowPage:', this.nowPage)
			},
		}
	}
</script>

<style scoped>
	.box {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.con-table {
		height: calc(100% - 106px);
	}

	.top .left li {
		width: auto;
	}
	.con-table table tr .icon_box.submit .icon{
		cursor: no-drop !important;
	}
</style>