<template>
    <div class="box">
        <div class="left">
            <div class="search">
                <input v-model="search_text" placeholder="search" @keyup.enter="search">
                <img @click="search" src="../../assets/img/search.png">
            </div>
            <div class="left-con">
                <div class="folder">
                    <ul>
                        <li :class="folder == 'user' ? 'active' : ''" @click="folder = 'user'">
                            <img v-if="folder == 'user'" src="../../assets/img/chat/order_0.png">
                            <img v-if="folder != 'user'" src="../../assets/img/chat/order_1.png">
                            <div>User</div>
                        </li>
                        <!-- <li :class="folder == 'booster' ? 'active' : ''" >
                            <img src="../../assets/img/chat/booster_1.png">
                            <div>Booster</div>
                        </li> -->
                        <li :class="folder == 'admin' ? 'active' : ''" @click="folder = 'admin'">
                            <img v-if="folder == 'admin'" src="../../assets/img/chat/admin_0.png">
                            <img v-if="folder != 'admin'" src="../../assets/img/chat/admin_1.png">
                            <div>Admin</div>
                        </li>
                        <li :class="folder == 'support'? 'active' : ''" @click="folder = 'support'">
                            <img v-if="folder == 'support'" src="../../assets/img/chat/support_0.png">
                            <img v-if="folder != 'support'" src="../../assets/img/chat/support_1.png">
                            <div>Support</div>
                        </li>
                        <li :class="folder == 'guestSupport' ? 'active' : ''" @click="folder = 'guestSupport'">
                            <img v-if="folder == 'guestSupport'" src="../../assets/img/chat/support_0.png">
                            <img v-if="folder != 'guestSupport'" src="../../assets/img/chat/support_1.png">
                            <div>Guest support</div>
                        </li>

                    </ul>
                    <!-- <div class="add-box">
                        <img src="../../assets/img/chat/add.png">
                        <div>Add new</div>
                    </div> -->
                </div>
                <div class="chatList">

                    <el-scrollbar ref="scroll0" id="list-scroll" class="scroll" :height="supportScrollbar"
                        @scroll="supportScroll">
                        <ul v-if="this.folder != 'support'&&folder!='guestSupport' ">
                            <li v-for="(item, index) in chatList" :key="index"
                                :class="other && other.id == item.id ? 'active' : ''" @click="chooseOther(item, index)">
                                <div class="l">
                                    <div class="img-box">
                                        <img :src="item.avatar">
                                        <div class="green" v-if="item.status == 1"></div>
                                    </div>
                                    <div class="item-box">
                                        <div class="name">{{ item.username || 'Not upload' }}</div>
                                        <div class="txt">{{ item.role }}</div>
                                    </div>
                                </div>
                                <div class="item-box">
                                    <div class="txt">{{ getDate(item.updated_at) }}</div>
                                    <div class="unread" v-if="item.unread > 0">{{ item.unread }}</div>
                                </div>
                            </li>
                        </ul>
                        <ul style="overflow: hidden;" v-else>
                            <li v-for="(item, index) in chatList" :key="index"
                                :class="other && other.id == item.id ? 'active' : ''" @click="chooseOther(item, index)">
                                <div class="l">
                                    <div class="img-box">
                                        <img :src="item.user_avatar">

                                        <div class="green" v-if="item.user_status == 1"></div>
                                    </div>
                                    <div class="item-box">
                                        <div class="name">{{ item.username || item.user_im_id }}</div>
                                        <div class="txt">{{ item.user_type == 1 ? 'Admin' : item.user_type == 2 ? 'User' :
                                            'Guest'
                                        }}</div>
                                    </div>
                                </div>
                                <div class="item-box">
                                    <div class="txt">{{ getDate(item.updated_at) }}</div>
                                    <div class="unread" v-if="item.unread > 0">{{ item.unread }}</div>
                                </div>
                            </li>
                            <div v-loading="loading" element-loading-text="Loading..."
                                element-loading-svg-view-box="-10, -10, 50, 50"
                                element-loading-background="rgba(122, 122, 122, 0.8)" style="width: 100%"></div>
                        </ul>
                    </el-scrollbar>
                </div>
            </div>
        </div>
        <div class="right" v-if="other" v-loading="sendMessageLoading">
            <div class="chat-top">
                <div class="user-box">
                    <div class="img-box" v-if="folder == 'support'||folder=='guestSupport' ">
                        <img :src="other.user_avatar">
                        <div class="green" v-if="other.user_status == 1"></div>
                    </div>
                    <div class="img-box" v-else>
                        <img :src="other.avatar">
                        <div class="green" v-if="other.status == 1"></div>
                    </div>
                    <div class="name-box">
                        <div class="name">{{ other.username }}</div>
                        <div class="txt">{{ other.status == 1 ? 'Online' : 'Offline' }}</div>
                    </div>
                </div>
            </div>

            <div>
                <el-scrollbar ref="scroll" id="chatBox" class="chat_con inner" :height="scrollbar" @scroll="scroll">
                    <ul class="inner_ul">
                        <li :class="item.sendID == other.user_im_id ? 'other' : 'me'" v-for="(item, index) in recordList"
                            :key="index">
                            <img v-if="item.sendID == other.user_im_id" :src="item.senderFaceUrl">
                            <div>
                                <div class="name">{{ item.senderNickname }}</div>
                                <p v-if="item.contentType == 101">{{ item.textElem.content }}</p>
                                <el-image v-if="item.contentType == 102" :src="item.pictureElem.snapshotPicture.url"
                                    :zoom-rate="1.2" :max-scale="7" :min-scale="0.2"
                                    :preview-src-list="[item.pictureElem.snapshotPicture.url]" :initial-index="1"
                                    fit="cover" />
                            </div>
                            <img v-if="item.sendID != other.user_im_id" :src="item.senderFaceUrl">
                        </li>
                    </ul>
                </el-scrollbar>
            </div>
            <div class="chat-bottom">
                <div class="more">
                    <button v-if="showEnd" class="end" @click="endChat">
                        End chat
                    </button>
                    <img @click="openEndChat" src="../../assets/img/chat/more.png">
                </div>
                <div class="file-box">
                    <img src="../../assets/img/chat/link.png">
                    <input type="file" ref="fileInput" id="uploads" accept="image/png, image/jpeg, image/gif, image/jpg"
                        @change="uploadImg">
                </div>

                <div class="inp-box">
                    <input v-model="send_text" :placeholder="other.status == 1 ? 'This support has ended' : 'Your message'"
                        @keyup.enter="send" :disabled="other.status">
                    <img style="margin:0 10px 0 0;" src="../../assets/img/chat/smile.png">


                </div>
                <img src="../../assets/img/chat/send.png" @click="send">
            </div>

        </div>

        <!-- <div class="pop-bg" v-if="showPreview_img">
            <el-image  v-if="showPreview_img" style="width: 100px; height: 100px" :src="preview_img.url" :zoom-rate="1.2" :max-scale="7"
            :min-scale="0.2" :preview-src-list="preview_img.url" :initial-index="4" fit="cover" />
        </div> -->
    </div>
</template>
<script>
import Api from "../../utils/api"
import { ElMessage, ElLoading } from 'element-plus'
import { getSDK } from '../../utils/openIM';
import {
    CbEvents
} from "../../utils/openIM/constant/index.js";
// const OpenIM = getSDK();
import { apiAddr,wsAddr } from '../../utils/config.js'
export default {
    data() {
        return {
            send_text: '',
            search_text: '',
            chatList: [],
            other: null,
            otherIndex: null,
            record_page: 1,
            record_total: 0,
            recordList: [],
            scrollbar: '',
            folder: 'user',
            nowPage: 1,
            supportScrollbar: '',
            loading: false,
            total: '',
            showEnd: false,
            isEndChat: false,
            send_file: '',
            send_type: 101,//102img
            preview_list: [],
            sendMessageLoading:false
        }
    },
    watch: {
        other(e) {
            if (!e) {
                this.otherIndex = null;
                return
            }
            this.record_page = 1;
            this.recordList = [];
            if (e.unread > 0) {
                this.chatRead();
            }
            this.getChatRecord();
        },
        folder(e) {
            this.send_text = '';
            this.search_text = '';
            this.other = null;
            this.chatList = [];
            this.showEnd = false;
            if (e == 'support'||e=="guestSupport") {
                this.getChatList_support();
            } else {
                this.getChatList();
            }
        }
    },
    mounted() {
        let obj = document.getElementsByClassName('chatList')[0];
        this.supportScrollbar = (obj.clientHeight) + 'px';

        const wrap = this.$refs.scroll0.wrapRef;
        wrap.removeEventListener('scroll', () => { this.scrollBarEv(wrap) })
        wrap.addEventListener('scroll', () => { this.scrollBarEv(wrap) })

        this.getChatList();
        // this.getChatList_support();
        setTimeout(() => {
            this.getLoginStatus();
        }, 1000);
        this.listenMessage();


    },

    methods: {
        async uploadImg(e) {
            if (e.target.files.length === 0) {
                return
            }
            if (e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/bmp' && e.target
                .files[0].type !== 'image/png') {
                this.$message.error('Image format error!');
                return false;
            }
            this.send_file = e.target.files[0];
            this.send_type = 102;
            this.send();

        },
        openEndChat() {
            if (this.folder == 'support'||this.folder=='guestSupport' ) {
                if (this.other.status == 0) {
                    this.showEnd = true;
                }

            }
        },
        endChat() {
            Api.supportStatus({
                support_id: this.other.id
            }).then(res => {
                if (res) {
                    if (res.code == 200) {
                        this.other.status = 1;
                        this.showEnd = false;
                    } else {
                        ElMessage.error(res.message);
                    }
                }
            })
        },
        supportScroll(e) {


        },
        scrollBarEv(wrap) {
            // console.log(wrap)
            let poor = wrap.scrollHeight - wrap.clientHeight;
            if (
                poor == parseInt(wrap.scrollTop) ||
                poor == Math.ceil(wrap.scrollTop) ||
                poor == Math.floor(wrap.scrollTop)
            ) {
                if (this.folder != 'support') {
                    return
                }
                if (this.chatList.length < this.total) {
                    this.loading = true;
                    this.nowPage++;
                    this.getChatList_support();
                }
            }

        },
        chatRead() {
            let data = {};
            if (this.folder == 'support'||this.folder=='guestSupport' ) {
                data = {
                    support_id: this.other.id
                }
            } else {
                data = {
                    group_id: this.other.group_id
                }
            }
            Api.chatRead(data).then(res => {
                if (res.code == 200) {
                    this.chatList[this.otherIndex].unread = 0;
                    this.$forceUpdate();
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        chooseOther(item, index) {
            this.other = item;
            this.otherIndex = index;
        },
        getChatRecord() {
            if (this.record_page == 1) {
                this.recordList = [];
            }
            let obj
            if (this.folder == 'support'||this.folder=='guestSupport') {
                obj = {
                    receive_id: this.other.user_id || this.other.email,
                    receive_type: this.other.user_type,
                    support_id: this.other.id,
                    limit: 20,
                    page: this.record_page,
                }
            } else {
                obj = {
                    group_id: this.other.group_id,
                    receive_type: 2,
                    limit: 20,
                    page: this.record_page,
                }
            }
            Api.chatRecord(obj).then(res => {
                if (res.code == 200) {
                    for (let i in res.data.data) {
                        this.recordList.unshift(res.data.data[i]);
                    }
                    this.record_total = res.data.meta.pagination.total;
                    if (this.record_page == 1) {
                        // if (this.recordList.length > 0) {
                        //     this.chat_id = res.data.data[0].id;
                        //     console.log(this.chat_id)
                        // }
                        this.scrollBottom();
                    }
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        scrollBottom() {
            this.$nextTick(() => {
                let d = document.getElementsByClassName('inner_ul')[0];
                d.scrollIntoView(false);
            })
        },
        scroll(e) {
            if (e.scrollTop == 0 && this.recordList.length > 0) {
                console.log('scrollTop：0')
                if (this.recordList.length < this.record_total) {
                    this.record_page++;
                    this.getChatRecord();
                }
            }
        },
        listenMessage() {
            let that = this;
           this.$OpenIM.on(CbEvents.OnRecvNewMessages, ({
                data
            }) => {
                // data is the new message
                console.log('OnRecvNewMessage=========')
                console.log(data)
                data.forEach((item, index) => {
                    that.recordList.push(item)
                    that.record_total++;
                })
                console.log(this.recordList)
                // console.log(that.messageList)
                that.scrollBottom()
            });

        },
        loginIm() {
            //dev
            // const config = {
            // 	userID: localStorage.getItem('chat_userID'), // IM user userID
            // 	token: localStorage.getItem('chat_token'),
            // 	platformID: 5, //  1: IOS, 2: Android, 3: Windows, 4: OSX, 5: Web, 6: MiniWeb, 7: Linux, 8: Android Pad, 9: IPad, 10: admin
            // 	apiAddr: 'http://im.digitalsnail.cn:10002', // IM api address, generally `http://xxx:10002` or `https://xxx/api
            // 	wsAddr: 'ws://im.digitalsnail.cn:10001' // IM ws address, generally `ws://your-server-ip:10001`(open-im-sdk-wasm) or `ws://your-server-ip:10003`(open-im-sdk)
            // }
            // st
            const config = {
                userID: localStorage.getItem('chat_userID'), // IM user userID
                token: localStorage.getItem('chat_token'),
                platformID: 5, //  1: IOS, 2: Android, 3: Windows, 4: OSX, 5: Web, 6: MiniWeb, 7: Linux, 8: Android Pad, 9: IPad, 10: admin
                apiAddr: apiAddr, // IM api address, generally `http://xxx:10002` or `https://xxx/api
                wsAddr: wsAddr // IM ws address, generally `ws://your-server-ip:10001`(open-im-sdk-wasm) or `ws://your-server-ip:10003`(open-im-sdk)
            }
            //prod
            // const config = {
            // 	userID: localStorage.getItem('chat_userID'), // IM user userID
            // 	token: localStorage.getItem('chat_token'),
            // 	platformID: 5, //  1: IOS, 2: Android, 3: Windows, 4: OSX, 5: Web, 6: MiniWeb, 7: Linux, 8: Android Pad, 9: IPad, 10: admin
            // 	apiAddr: 'http://www.wconnect.work/imApi',
            // 	wsAddr: 'ws://www.wconnect.work/im' // IM ws address, generally `ws://your-server-ip:10001`(open-im-sdk-wasm) or `ws://your-server-ip:10003`(open-im-sdk)
            // }
            this.$OpenIM.login(config).then(res => {
                console.log(res)
                console.log("login suc...");
            }).catch(err => {
                console.log("login failed...");
                console.log(err)
                console.log("login failed...");

            })
        },
        // logOut(e) {
        //     OpenIM.logout().then(res => {
        //         Api.chatLogout().then(res => {
        //             if (res.code == 200) {
        //                 if (e == 1) { //1,logout 2,login angin
        //                     setCookie('chat_token', '')
        //                     setCookie('chat_userID', '')
        //                     setCookie('chat_expireTimeSeconds', '');
        //                 } else {
        //                     this.loginIm();
        //                 }

        //             }
        //         }).catch(err => {

        //         })
        //         console.log("loginOut suc...");
        //     }).catch(err => {
        //         console.log("loginOut failed...");
        //         console.log(err);
        //         setCookie('chat_token', '')
        //         setCookie('chat_userID', '')
        //         setCookie('chat_expireTimeSeconds', '');
        //     })
        // },

        getLoginStatus() {
            this.$OpenIM.getLoginStatus().then(({
                data
            }) => {
                console.log(data)
                // if (data !== 3 && data !==2) {
                //     this.loginIm();
                // }
                // if (data == 1) {
                //     this.loginIm();
                // }
                // data: LoginStatus
            }).catch(({
                errCode,
                errMsg
            }) => {
                console.log(errCode, errMsg);
                // logOut(2);
                // Call failed
                //this.loginIm();
            });
        },
        getChatList_support() {
            // let loadingInstance = ElLoading.service({
            //     background: 'rgba(0, 0, 0, 0.2)',
            //     lock: true,
            // });
            Api.chatSupport({
                search: this.search_text,
                page: this.nowPage,
                type:this.folder=='guestSupport'?0:1
                // limit:13
            }).then(res => {
                if (res.code == 200) {
                    if (this.nowPage == 1) {
                        this.chatList = res.data.data;
                    } else {
                        for (let i in res.data.data) {
                            this.chatList.push(res.data.data[i])
                        }
                    }

                    this.total = res.data.meta.pagination.total;
                    if (this.chatList.length > 0) {
                        this.other = res.data.data[0];
                        this.otherIndex = 0;
                        let obj = document.getElementsByClassName('box')[0];
                        this.scrollbar = (obj.clientHeight - 140) + 'px';
                    }
                    this.loading = false;
                } else {
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                    ElMessage.error(res.message)
                }

            })
        },
        getChatList() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.chatList({
                type: this.folder == 'user' ? 2 : 1,
                search: this.search_text
            }).then(res => {
                if (res) {
                    if (res.code == 200) {
                        this.chatList = res.data.data;
                        if (this.chatList.length > 0) {
                            let obj = document.getElementsByClassName('box')[0];
                            this.scrollbar = (obj.clientHeight - 140) + 'px';
                            // if (this.$route.query.group_id) {
                            //     for (let i in this.chatList) {
                            //         if (this.chatList[i].group_id == this.$router.query.group_id) {
                            //             this.other = this.chatList[i];
                            //         }
                            //     }
                            // } else {
                            this.other = res.data.data[0];
                            this.otherIndex = 0;
                            // }

                        }

                    } else {
                        ElMessage.error(res.message)
                    }
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })

        },
        search() {
            console.log('search:', this.search_text);
            this.nowPage = 1;
            this.other = null;
            if (this.folder == 'support'||this.folder=="guestSupport") {
                this.getChatList_support();
            } else {
                this.getChatList();
            }
        },
        send_private() {
            this.sendMessageLoading = true;
            let params;
            if (this.send_type == 102) {
                params = new FormData()
                params.append("image", this.send_file);
                params.append("content_type", this.send_type);
                params.append("receive_id", this.other.user_id || this.other.email);
                params.append("receive_type", this.other.user_type);
                params.append("support_id", this.folder == 'support'||this.folder=='guestSupport' ? this.other.id : null);
            } else {
                params = {
                    content_type: this.send_type,
                    receive_id: this.other.user_id || this.other.email,
                    receive_type: this.other.user_type,
                    send_message: this.send_text,
                    support_id: this.folder == 'support'||this.folder=="guestSupport" ? this.other.id : null,
                    // order_id: ''
                }
            }
            Api.chatSendPrivate(params).then(res => {
                if (res.code == 200) {
                    this.send_text = '';
                    this.send_file = '';
                    this.send_type = 101;
                    this.scrollBottom();
                    this.sendMessageLoading = false;
                } else {
                    ElMessage.error(res.message)
                    this.sendMessageLoading = false;
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        send_group() {
            this.sendMessageLoading = true;
            let params;
            if (this.send_type == 102) {
                params = new FormData()
                params.append("image", this.send_file);
                params.append("content_type", this.send_type);
                params.append("group_id", this.other.group_id);
            } else {
                params = {
                    content_type: this.send_type,
                    send_message: this.send_text,
                    group_id: this.other.group_id,
                    // order_id: this.chat_info.order_id
                }
            }

            Api.chatSendGroup(params).then(res => {
                if (res.code == 200) {
                    this.send_text = '';
                    this.send_file = '';
                    this.send_type = 101;
                    this.scrollBottom();
                    this.sendMessageLoading = false;
                } else {
                    ElMessage.error(res.message)
                    this.sendMessageLoading = false;
                }
            }).catch(err => {
                ElMessage.error(err.message);
                this.sendMessageLoading = false;
            })
        },
        send() {
            if (this.isEndChat) {
                return
            }
            if (this.send_text || this.send_file) {
                if (this.folder == "support"||this.folder=="guestSupport") {
                    this.send_private();
                } else {
                    this.send_group();
                }

            }

        },
        getDate(e) {
            const date = new Date(e ? e * 1000 : '');
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            month = month > 9 ? month : '0' + month;
            day = day > 9 ? day : '0' + day;
            hour = hour > 9 ? hour : '0' + hour;
            minute = minute > 9 ? minute : '0' + minute;
            return `${hour}:${minute}`
        }
    },
}
</script>
<style>
.el-image-viewer__close {
    background-color: var(--background-light3);
}

.el-image-viewer__actions {
    background-color: var(--background-light3);
}

.box .right .inner ul li .el-image img {
    border-radius: 0px;
    border: 0;
    margin-top: 5px;
    cursor: pointer;
    width: auto;
    height: auto;
    object-fit: cover;
    max-width: 200px;
    max-height: 200px;
}
</style>
<style scoped>
.box {
    display: flex;
    height: 100%;
}

.box .left {
    width: 30%;
    height: 100%;
}

.box .left .search {
    border: 1px solid var(--border);
    padding: 6px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 20px;
    margin: 10px 0 20px 0;
}

.box .left .search input {
    width: calc(100% - 15px);
    color: #fff;
}

.box .left .search img {
    width: 15px;
    height: 15px;
    object-fit: cover;
    border-left: 1px solid var(--border);
    padding-left: 10px;
    cursor: pointer;
}

.box .left .left-con {
    display: flex;
    height: calc(100% - 62px);
}

.box .left .left-con .folder {
    background-color: var(--background-light2);
    height: 100%;
    width: 20%;
    border-radius: 20px;
}

.box .left .left-con .folder ul {
    height: calc(100% - 70px);
    overflow-y: scroll;
}

.box .left .left-con .folder ul li {
    border-radius: 20px;
    padding: 12px 0;
    color: var(--text-color-light);
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}

.box .left .left-con .folder ul li.active {
    color: var(--text-color-purple);
    background-color: var(--text-color-purple0);
}

.box .left .left-con .folder ul li img {
    width: 16px;
    object-fit: cover;
    margin-bottom: 10px;
}

.box .left .left-con .folder .add-box {
    border-top: 1px solid var(--border);
    color: var(--text-color-purple);
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 5px;
    height: 70px;
    cursor: pointer;
}

.box .left .left-con .folder .add-box img {
    width: 15px;
    height: 15px;
    object-fit: cover;
    margin: 10px 0;
}

.box .left .chatList {
    width: calc(80% - 10px);
    margin-left: 10px;
    height: 100%;
}

.box .left .chatList ul {
    height: 100%;
    overflow-y: scroll;
}

.box .left .chatList ul li {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 8px;
    border-radius: 12px;
}

.box .left .chatList ul li.active {
    background-color: var(--background-light2);
}

.box .left .chatList ul li .l {
    display: flex;
}

.box .left .chatList ul li .l .img-box {
    position: relative;
    margin-right: 10px;
}

.box .left .chatList ul li .l .img-box img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--img-border);

}

.box .left .chatList ul li .l .img-box .green {
    width: 5px;
    height: 5px;
    background-color: var(--text-color-green);
    position: absolute;
    left: 3px;
    top: 0px;
    border-radius: 50%;

}

.box .left .chatList ul li .item-box {
    text-align: left;
}

.box .left .chatList ul li .item-box .name {
    color: var(--text-color);
    font-size: 14px;
    line-height: 18px;

}

.box .left .chatList ul li .item-box .txt {
    color: var(--text-color-light);
    font-size: 10px;
    line-height: 18px;
    font-weight: 100;
}

.box .left .chatList ul li .item-box .unread {
    color: var(--text-color);
    background-color: var(--submit);
    border-radius: 50%;
    padding: 2px 5px;
    text-align: center;
    display: inline-block;
    font-size: 10px;
}

.box .right {
    width: calc(70% - 20px);
    margin-left: 20px;
    border-radius: 20px;
    height: 100%;
    background-color: var(--background-light2);
}

.box .right .chat-top {
    border-bottom: 1px solid var(--img-border);
    padding: 10px 15px;
    height: 40px;
}

.box .right .chat-top .user-box {
    display: flex;
}

.box .right .chat-top .user-box .img-box {
    position: relative;
    margin-right: 5px;
}

.box .right .chat-top .user-box img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--img-border);
}

.box .right .chat-top .user-box .green {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 0px;
    background-color: var(--text-color-green);
}

.box .right .chat-top .user-box .name-box {
    text-align: left;
}

.box .right .chat-top .user-box .name-box .name {
    color: var(--text-color);
    font-size: 14px;
    line-height: 20px;
}

.box .right .chat-top .user-box .name-box .txt {
    color: var(--text-color-light);
    font-size: 12px;
    line-height: 20px;
    font-weight: 100;
}

.box .right .inner {
    padding: 10px 15px;
    width: calc(100% - 30px);
    height: calc(100vh - 300px);
}


.box .right .inner ul li {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    width: 100%;
}

.box .right .inner ul li.other {
    justify-content: flex-start;
}

.box .right .inner ul li.other img {
    margin: 0 2px 0 0;
}

.box .right .inner ul li .el-image {
    max-width: none;
}



.box .right .inner ul li img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 0 0 2px;
    border: 1px solid var(--img-border);
}

.box .right .inner ul li div .name {
    color: var(--text-color);
    font-weight: bold;
    text-align: left;
    padding: 0;
    font-size: 14px;
}

.box .right .inner ul li div p {
    color: var(--text-color-light);
    line-height: 16px;
    text-align: left;
    word-wrap: break-word;
}

.box .right .inner ul li div {
    padding: 5px 8px;
    border-radius: 5px;
    background-color: var(--background-light);
    max-width: calc(100% - 60px)
}

.box .right .inner ul li.me div {
    background-color: var(--background-blue);


}

.box .right .chat-bottom {
    display: flex;
    padding: 10px 15px;
    height: 40px;
    border-top: 1px solid var(--img-border);
    display: flex;
    align-items: center;
}

.box .right .chat-bottom .more {
    position: relative;
}

.box .right .chat-bottom .more:hover .end {
    /* display: block; */
}

.box .right .chat-bottom .end {
    /* display:none; */
    left: -10px;
    width: 100px;
    top: -75px;
    position: absolute;
    background: var(--background-light);
    padding: 20px;
    clip-path: polygon(15px 0px, calc(100% - 15px) 0, 100% 15px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 calc(100% - 15px), 0 15px);
    color: var(--text-color);
}

.box .right .chat-bottom img {
    height: 18px;
    object-fit: cover;
    margin: 0 10px;
    cursor: pointer;
}

.box .right .chat-bottom .file-box {
    position: relative;
    cursor: pointer;
}

.box .right .chat-bottom .file-box input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;

}

.box .right .chat-bottom .inp-box {
    width: 100%;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    padding: 5px 10px;
    padding-right: 0;
    height: 20px;
    display: flex;
}

.box .right .chat-bottom .inp-box input {
    width: calc(100% - 20px);
    line-height: 20px;
    color: var(--text-color);
}

.pop-box {
    width: 60%;
    object-fit: contain;
}
</style>